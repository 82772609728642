import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    userProfileData: {
        brokerCedent: [],
        cedentOrgUnitList: [],
        individual: {
            id: 0,
            personId: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            phone: '',
            roleTitle: '',
            roleCode: ''
        },
        orgUnit: {
            clientPartyId: null,
            default: {
                lob: null,
                stdCommPct: null
            },
            orgUnitId: null,
            orgUnitName: '',
            orgUnitTypeCode: '',
            countryCode: '',
            paperCompany: '',
        }
    }
};
const userProfileDetailsSlice = createSlice({
    name: 'userProfileDetails',
    initialState,
    reducers: {
        setUserProfileDetails: (state, action) => {
            state.userProfileData = action.payload;
        },
        updateRoleCode: (state, action) => {
            state.userProfileData.individual.roleCode = action.payload;
        },
        updateOrgUnitName: (state, action) => {
            state.userProfileData.orgUnit.orgUnitName = action.payload;
        }
    },
});
export const { setUserProfileDetails, updateRoleCode, updateOrgUnitName } = userProfileDetailsSlice.actions;
export default userProfileDetailsSlice.reducer;
