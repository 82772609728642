// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bcNwJI4gzW3r0ov8IuZ30A\\=\\= {\r\n    font-weight: Bold;\r\n    font-size: 10px;\r\n    color: red;\r\n    font-family: AvenirNextLTPro-Bold;\r\n\r\n}\r\n.NXXl1FhjBuDv1D-tH\\+QTMw\\=\\= {\r\n    padding: 9px 3px 9px 10px;\r\n    width: 170px;\r\n    border: 1px solid #80808075;\r\n    border-radius: 5px;\r\n    background-color: #fff\r\n}\r\n\r\n.kRozIHXIeE64piSvxi0Kbg\\=\\= {\r\n    font-weight: Bold;\r\n    font-size: 11.5px;\r\n    color: red;\r\n    text-align: center;\r\n    padding: 20px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/referrals/uwReferrals.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,UAAU;IACV,iCAAiC;;AAErC;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB;AACJ;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".preminumMessageStyle {\r\n    font-weight: Bold;\r\n    font-size: 10px;\r\n    color: red;\r\n    font-family: AvenirNextLTPro-Bold;\r\n\r\n}\r\n.preminumVal {\r\n    padding: 9px 3px 9px 10px;\r\n    width: 170px;\r\n    border: 1px solid #80808075;\r\n    border-radius: 5px;\r\n    background-color: #fff\r\n}\r\n\r\n.accessDeniedStyle {\r\n    font-weight: Bold;\r\n    font-size: 11.5px;\r\n    color: red;\r\n    text-align: center;\r\n    padding: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preminumMessageStyle": "bcNwJI4gzW3r0ov8IuZ30A==",
	"preminumVal": "NXXl1FhjBuDv1D-tH+QTMw==",
	"accessDeniedStyle": "kRozIHXIeE64piSvxi0Kbg=="
};
export default ___CSS_LOADER_EXPORT___;
