import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BoxAtom, ButtonAtom, CheckboxAtom, TypographyAtom, useTheme } from '@epam/reactjs-common';
import BoxStyled from '../../../@atoms/layout/box/index.atom';
import React, { useState, useEffect } from 'react';
import Divider from '@epam/reactjs-common/atoms/layout/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import './locationReview.css';
import { appendDollar, appendPercentage } from '../../../utils/currency.util';
import { filterByRefType, findLabelById } from '../../../utils/refDataFilters/refDataFilters';
import TextFieldComponent from '../../../components/TextField/textField.Component';
import { convertShorthandToNumber } from '../../../utils/CommonUtilties';
import DividerStyled from '../../../@atoms/layout/divider/index.atom';
const LocationComponent = () => {
    var _a;
    const calculateTotalSumInsured = (building, content, bi) => {
        const num1 = typeof (building) === 'undefined' ? 0 : parseInt(building.toString());
        const num2 = typeof (content) === 'undefined' ? 0 : parseInt(content.toString());
        const num3 = typeof (bi) === 'undefined' ? 0 : parseInt(bi.toString());
        return num1 + num2 + num3;
    };
    const calculateITV = (building, grossArea) => {
        if (grossArea === 0)
            return 0; // Prevent division by zero
        // Calculate the result
        const result = building / grossArea;
        // Round to 2 decimal places
        const roundedToTwoDecimal = parseFloat(result.toFixed(3));
        // Extract the hundredth and thousandth places
        const hundredthPlace = Math.floor(roundedToTwoDecimal * 100) % 10;
        const thousandthPlace = Math.floor(roundedToTwoDecimal * 1000) % 10;
        // Determine if we need to round up or down
        const finalResult = thousandthPlace >= 5
            ? Math.ceil(roundedToTwoDecimal * 100) / 100
            : Math.floor(roundedToTwoDecimal * 100) / 100;
        return finalResult;
    };
    const themehook = useTheme();
    const [locationList, setLocationList] = useState([]);
    const dispatch = useDispatch();
    //dispatch(setLoctionAddressDetails(locationList));
    const locationBuildingInfoState = useSelector((state) => state.locationAddressDetails.loctionaddressDetailsData);
    const filteredLocation = locationBuildingInfoState === null || locationBuildingInfoState === void 0 ? void 0 : locationBuildingInfoState.filter(x => x.isDeleted === false);
    // Single location load
    const [hasEffectRun, setHasEffectRun] = useState(false);
    useEffect(() => {
        if (!hasEffectRun) {
            if (filteredLocation.length === 0 || locationList.length === 0) {
                if (filteredLocation.length > 0) {
                    setLocationList(filteredLocation);
                    console.log("review locationlis", filteredLocation);
                }
                else {
                    const newLocation = { addressId: Date.now(), isKeyAddress: false, isDeleted: false };
                    setLocationList([...locationList, newLocation]);
                }
            }
            setHasEffectRun(true);
        }
    }, [hasEffectRun]);
    let ddlOccupancy = filterByRefType("OccTyCode");
    let ddlConstruction = filterByRefType("ConstrTyCode");
    const ddlStateProvince = filterByRefType("StateProvince");
    const countryOptions = filterByRefType("Country");
    const ddlSprinklered = [
        { optionId: "0", label: '--' },
        { optionId: "1", label: 'Yes' },
        { optionId: "2", label: 'No' },
    ];
    const fireProtectionDropDownList = [
        { id: 0, label: "--" },
        { id: 1, label: "1" },
        { id: 2, label: "2" },
        { id: 3, label: "3" },
        { id: 4, label: "4" },
        { id: 5, label: "5" },
        { id: 6, label: "6" },
        { id: 7, label: "7" },
        { id: 8, label: "8" },
        { id: 9, label: "9" },
        { id: 10, label: "10" },
    ];
    const commaseparation = (value) => {
        if (!value && value !== 0)
            return ""; // Check for null, undefined, and NaN
        const numberValue = parseFloat(value + ""); // Parse the string value to a floating-point number
        if (isNaN(numberValue))
            return "";
        const formattedValue = numberValue.toLocaleString(undefined, {
            minimumFractionDigits: 0
        });
        return formattedValue;
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: 'locationdivReview' }, { children: [_jsx("p", { children: _jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', color: '#00003f' } }, { children: _jsx(Typography, Object.assign({ style: Object.assign({}, themehook.customStyles.sectionTitleStyle) }, { children: "Location Address" })) })) }), _jsx("div", { children: (_a = filteredLocation === null || filteredLocation === void 0 ? void 0 : filteredLocation.filter(x => x.isDeleted === false)) === null || _a === void 0 ? void 0 : _a.map((item, index) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
                        return (_jsxs("form", Object.assign({ style: { marginBottom: "2%", marginTop: '2%' } }, { children: [_jsxs(Accordion, Object.assign({ defaultExpanded: true }, { children: [_jsx(AccordionSummary, Object.assign({ style: { backgroundColor: 'lightgrey' }, expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel2-content", id: "panel2-header" }, { children: _jsx("p", Object.assign({ style: { marginBottom: '0px' } }, { children: _jsxs(Typography, Object.assign({ variant: "label1", sx: {
                                                        color: '#00003f', fontSize: "14px", fontWeight: "500", marginLeft: '0px', paddingTop: '0px', paddingBottom: '5px'
                                                    } }, { children: ["Address Location ", index + 1] })) })) })), _jsx("div", Object.assign({ style: { backgroundColor: 'lightgrey' } }, { children: _jsxs(AccordionDetails, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', fontFamily: 'AvenirNextLTPro-regular', color: 'black', padding: '0px 0px 30px 0px', marginLeft: '-10px', marginTop: '-15px' } }, { children: [_jsx(CheckboxAtom, { disabled: true, checked: item.isKeyAddress, name: 'isKeyAddress', value: item.isKeyAddress, sx: { outlineColor: '#989BA0' }, size: "small" }), _jsx("span", Object.assign({ style: {
                                                                    fontSize: "10px", alignContent: "center",
                                                                    fontWeight: "bolder", color: "rgba(0,0,0,0.6)"
                                                                } }, { children: "Use as Key Location" }))] })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: '4px', marginTop: '-15px' } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { marginTop: '-15px', fontSize: '12px', marginLeft: '-15px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'countryReview', sx: { width: '300px', Color: 'red' }, name: "country", label: "Country", value: findLabelById(countryOptions, item.country), InputLabelProps: {
                                                                            shrink: true,
                                                                        }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '-15px', fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', width: '160px', sx: { width: '100%', color: 'black' }, name: "postalCode", isRequired: true, label: "Postal Code", value: item.postalCode, InputLabelProps: {
                                                                            shrink: true,
                                                                        }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '13px', width: "150px" } }, { children: _jsx(ButtonAtom, Object.assign({ className: "btn btn-disabled", variant: "contained", sx: { height: '34px', padding: "12px", marginTop: '3px',
                                                                            marginLeft: '5px'
                                                                        }, style: { fontWeight: 1000, border: "0px", borderRadius: "5px", width: "115px", fontSize: "12px", textTransform: 'none', color: '#303740', backgroundColor: '#B0B0B0', marginTop: '15px' } }, { children: "Search" })) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: '4px' } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", marginTop: '5px', width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { marginTop: '-13px', fontSize: '12px', marginLeft: '-15px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { width: '150px', id: 'cityReview', sx: { width: '100%', color: 'black' }, name: "city", isRequired: true, label: "City", value: item.city, InputLabelProps: {
                                                                            shrink: true,
                                                                        }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '-13px', fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { width: '160px', id: 'stateReview', sx: { width: '100%', color: 'black' }, name: "stateProvinceCode", isRequired: true, label: "State/Province", value: (_a = ddlStateProvince === null || ddlStateProvince === void 0 ? void 0 : ddlStateProvince.find(x => x.id === item.stateProvinceCode)) === null || _a === void 0 ? void 0 : _a.label, InputLabelProps: {
                                                                            shrink: true,
                                                                        }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '-13px', fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { value: item.addressLine1, label: 'Address', isRequired: true, id: 'address1review', labelId: "address1review", width: '300px', isDisabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '-13px', fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { value: item.addressLine2, label: 'Address 2', isRequired: false, id: 'address2review', labelId: "address2review", width: '300px', isDisabled: true }) }))] })) })), _jsx(Divider, { sx: { background: 'black', lineHeight: '10px', marginLeft: '-17px', marginRight: '17px' } }), _jsx("br", {}), _jsxs("div", Object.assign({ style: { marginLeft: '-6px' } }, { children: [_jsx(TypographyAtom, Object.assign({ id: "buildingInfoLabel", variant: "label1", sx: { fontSize: "14px", fontWeight: "500", marginTop: '-15px', color: '#00003f' } }, { children: "Building Information" })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px" } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', sx: { height: '36px', marginTop: '15px' }, name: "buildings", isRequired: true, label: "Buildings", value: appendDollar((_b = item.buildingDetails) === null || _b === void 0 ? void 0 : _b.buildings), InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', name: "content", isRequired: true, label: "Contents", value: appendDollar((_c = item.buildingDetails) === null || _c === void 0 ? void 0 : _c.content), InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', name: "businessInterruption", label: 'Time Element', isRequired: true, value: appendDollar((_d = item.buildingDetails) === null || _d === void 0 ? void 0 : _d.businessInterruption), InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { value: appendDollar(calculateTotalSumInsured(convertShorthandToNumber(((_e = item.buildingDetails) === null || _e === void 0 ? void 0 : _e.buildings) || 0), convertShorthandToNumber(((_f = item.buildingDetails) === null || _f === void 0 ? void 0 : _f.content) || 0), convertShorthandToNumber(((_g = item.buildingDetails) === null || _g === void 0 ? void 0 : _g.businessInterruption) || 0))) || 0, label: 'Total Sum Insured', isRequired: false, id: 'sumInsuredReview', labelId: "sumInsuredReview", width: '285px', isDisabled: true }) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", marginTop: '-15px', width: '100%' } }, { children: [_jsx("p", Object.assign({ style: {} }, { children: _jsx(TextFieldComponent, { id: 'locationreview', name: "construction", isRequired: true, label: "Construction", value: (_h = ddlConstruction === null || ddlConstruction === void 0 ? void 0 : ddlConstruction.find(x => { var _a; return x.id === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.construction); })) === null || _h === void 0 ? void 0 : _h.label, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, wrapperPadding: '10px 0px', width: '135px' }) })), _jsx("p", Object.assign({ style: {} }, { children: _jsx(TextFieldComponent, { id: 'locationreview', name: "occupancy", isRequired: true, label: "Occupancy", value: (_j = ddlOccupancy === null || ddlOccupancy === void 0 ? void 0 : ddlOccupancy.find(x => { var _a; return x.id === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.occupancy); })) === null || _j === void 0 ? void 0 : _j.label, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, wrapperPadding: '10px 0px', width: '135px' }) })), _jsx("p", Object.assign({ style: {} }, { children: _jsx(TextFieldComponent, { id: 'fireProtectionReview', name: "fireProtectionClass", isRequired: true, label: "Fire Protection Class", value: (_k = fireProtectionDropDownList === null || fireProtectionDropDownList === void 0 ? void 0 : fireProtectionDropDownList.find(x => { var _a; return x.id === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.fireProtectionClass); })) === null || _k === void 0 ? void 0 : _k.label, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, width: '135px', wrapperPadding: '10px 0px' }) })), _jsx("p", Object.assign({ style: {} }, { children: _jsx(TextFieldComponent, { id: 'locationreview', name: "deductible", isRequired: true, label: "Sprinklered", value: (_l = ddlSprinklered === null || ddlSprinklered === void 0 ? void 0 : ddlSprinklered.find(x => { var _a; return x.optionId === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.sprinkled); })) === null || _l === void 0 ? void 0 : _l.label, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, width: '135px', wrapperPadding: '10px 0px 10px 0px' }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', width: '280px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', width: '140px', name: "timeElement", isRequired: true, label: 'Indemnity Period', value: (((_m = item.buildingDetails) === null || _m === void 0 ? void 0 : _m.timeElement) === undefined) ? "12 Months" : ((_o = item.buildingDetails) === null || _o === void 0 ? void 0 : _o.timeElement) === 18 ? "18 Months" : "12 Months", InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, wrapperPadding: '10px 0px' }) })), _jsx("p", Object.assign({ style: { fontSize: '12px' } }, { children: _jsx(TextFieldComponent, { id: 'sumInsuredReview', width: '130px', name: "deductible", isRequired: false, label: "Deductible Type", value: ((_p = item.buildingDetails) === null || _p === void 0 ? void 0 : _p.deductibleType) === "Percentage" ? "% Percentage" : "$ Amount", InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, wrapperPadding: '10px 0px' }) })), _jsx("p", Object.assign({ style: { fontSize: '12px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', width: '125px', name: "deductible", isRequired: false, label: "Deductible", value: ((_q = item.buildingDetails) === null || _q === void 0 ? void 0 : _q.deductibleType) === "Percentage" ? appendPercentage((_r = item.buildingDetails) === null || _r === void 0 ? void 0 : _r.deductible) : appendDollar((_s = item.buildingDetails) === null || _s === void 0 ? void 0 : _s.deductible), InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, wrapperPadding: '10px 0px' }) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px" } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", width: '100%', marginTop: '-15px' } }, { children: [_jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', style: { height: '36px' }, name: "grossArea", width: '155px', isRequired: true, label: "Gross Area (Sq. ft.)", value: commaseparation((_t = item.buildingDetails) === null || _t === void 0 ? void 0 : _t.grossArea), InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'sumInsuredReview', style: { height: '36px' }, name: "itv", width: '155px', label: "ITV", value: (calculateITV(parseInt(((_u = item === null || item === void 0 ? void 0 : item.buildingDetails) === null || _u === void 0 ? void 0 : _u.buildings) || 0), parseInt(((_v = item.buildingDetails) === null || _v === void 0 ? void 0 : _v.grossArea) || 0))), InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '-10px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', width: '155px', name: "numberOfStories", isRequired: true, label: "Number of Stories", value: (_w = item.buildingDetails) === null || _w === void 0 ? void 0 : _w.numberOfStories, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px', marginLeft: '-10px', marginRight: '280px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', width: '155px', isRequired: true, label: "Year Built", value: (_x = item.buildingDetails) === null || _x === void 0 ? void 0 : _x.builtYear, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true }) })), _jsx("p", Object.assign({ style: { fontSize: '12px' } }, { children: _jsx(TextFieldComponent, { id: 'locationreview', width: '130px', name: "sir", label: "SIR", value: (_y = item.buildingDetails) === null || _y === void 0 ? void 0 : _y.sir, InputLabelProps: {
                                                                                    shrink: true,
                                                                                }, isDisabled: true, wrapperPadding: '10px 0px' }) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px" } }, { children: _jsx(BoxAtom, { sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", width: '100%', marginTop: '-15px' } }) }))] }))] }) }), item.addressId)] })), _jsx(DividerStyled, { sx: { borderBlockColor: 'black', padding: '10px 0px' }, orientation: "horizontal" })] })));
                    }) })] })) }));
};
export default LocationComponent;
