import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import './dropdown.css'
import BoxStyled from '../../@atoms/layout/box/index.atom';
import { TypographyAtom, useTheme } from '@epam/reactjs-common';
import TextfieldAtom from '@epam/reactjs-common/atoms/form/TextField';
const DateComponent = ({ id, label, labelId, dataArrDropdown, value, isRequired = false, handleDateChange, isDisabled = false, width, leftPaddingNone = false, isMarginLeft = false }) => {
    const themehook = useTheme();
    return (_jsxs(BoxStyled, Object.assign({ sx: { width: width, padding: leftPaddingNone ? "10px 1rem 10px 30px" : '0px', marginLeft: isMarginLeft ? "10px" : "0px", marginRight: '20px', marginBottom: '10px' } }, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', marginBottom: '8px' } }, { children: [_jsx(TypographyAtom, Object.assign({ id: labelId, sx: Object.assign({}, themehook.customStyles.labelStyle), component: "p" }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                            fontSize: '12px', color: 'red', paddingLeft: '5px'
                        }, component: "p" }, { children: "*" })))] })), _jsx(TextfieldAtom, { id: id, disabled: isDisabled, type: "date", InputLabelProps: {
                    shrink: true,
                }, value: value, style: { width: width }, onChange: (e) => handleDateChange(e.target.value) })] })));
};
export default DateComponent;
