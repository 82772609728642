import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectedItem: 'Home',
};
const leftNavSlice = createSlice({
    name: 'leftNav',
    initialState,
    reducers: {
        setLeftNav: (state, action) => {
            state.selectedItem = action.payload;
        },
    },
});
export const { setLeftNav } = leftNavSlice.actions;
export default leftNavSlice.reducer;
