import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxStyled from "../../../@atoms/layout/box/index.atom";
import { TypographyAtom, useTheme } from '@epam/reactjs-common';
import { setCountry, setReInsContractType, setBrokerNames, setClientName, setNameInsured, setBrokerId, setClientId, setDescription } from "../../../store/slices/businessOverviewReducer";
import { extractBrokerData } from "../../../utils/profileDataFilters/brokerOptionFilter";
import { extractClientData } from "../../../utils/profileDataFilters/clientOptionFilter";
import TextFieldComponent from "../../TextField/textField.Component";
import { filterByRefType, findLabelById } from "../../../utils/refDataFilters/refDataFilters";
import "./SubmissionReview.common.css";
export const BusinessOverviewContent = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    // const [countryValue, setCountryValue] = useState<string>(countryOptions[0].label)
    const dispatch = useDispatch();
    const country = useSelector((state) => state.businessOv.businessOverviewData.country);
    const countryIdData = useSelector((state) => state.businessOv.businessOverviewData.countryId);
    const brokerIdData = useSelector((state) => state.businessOv.businessOverviewData.brokerId);
    const clientIdData = useSelector((state) => state.businessOv.businessOverviewData.clientId);
    const reInsTypeIdData = useSelector((state) => state.businessOv.businessOverviewData.reInsContractTypeId);
    const reInsContractType = useSelector((state) => state.businessOv.businessOverviewData.reInsContractType);
    // const brokerName = useSelector((state: RootState) => state.retrivedSubmissionDetils.retrivedSubmissionData.submissionPayload.brokerId);
    const brokerName = useSelector((state) => state.businessOv.businessOverviewData.brokerName);
    const clientName = useSelector((state) => state.businessOv.businessOverviewData.clientName);
    const nameInsured = useSelector((state) => state.businessOv.businessOverviewData.nameInsured);
    const description = useSelector((state) => { var _a; return (_a = state.businessOv.businessOverviewData) === null || _a === void 0 ? void 0 : _a.description; });
    const brokerNameOptions = extractBrokerData();
    const clientNameOptions = extractClientData();
    const countryOptions = filterByRefType("Country");
    const reInsContractOptions = filterByRefType("ReinsType");
    const isLinkedPage = useSelector((state) => state.isLinked.isLinkedPage);
    let retrivedSubmissionData = useSelector((state) => state.retrivedSubmissionDetils.retrivedSubmissionData.submissionPayload);
    const { submissionCountry, reinsuranceContractType, brokerId, clientId, insuredName } = retrivedSubmissionData;
    const retrievedDesc = (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.description) !== null ? retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.description : '';
    const [dataMapped, setdataMapped] = useState(false);
    useEffect(() => {
        if (countryIdData) {
            const retrivedCountry = findLabelById(countryOptions, countryIdData);
            retrivedCountry && dispatch(setCountry(retrivedCountry));
        }
        if (reInsTypeIdData) {
            const retrivedReInsContractType = findLabelById(reInsContractOptions, reInsTypeIdData);
            retrivedReInsContractType && dispatch(setReInsContractType(retrivedReInsContractType));
        }
        if (brokerIdData) {
            const retrivedBrokerName = findLabelById(brokerNameOptions, brokerIdData);
            // handleBrokerNameChange(retrivedBrokerName!)
            retrivedBrokerName && dispatch(setBrokerNames(retrivedBrokerName));
            // retrivedBrokerName && dispatch(setBrokerId(retrivedBrokerName ?parseInt(findIdByLabel(brokerNameOptions,retrivedBrokerName)!):0))
        }
        if (clientIdData) {
            const retrivedClientName = findLabelById(clientNameOptions, clientIdData);
            retrivedClientName && dispatch(setClientName(retrivedClientName));
            // retrivedClientName &&  dispatch(setClientId(parseInt(findIdByLabel(clientNameOptions,retrivedClientName!)!))) 
        }
    });
    const mapRetrivedSubmission = () => {
        if (submissionCountry) {
            const retrivedCountry = findLabelById(countryOptions, submissionCountry);
            retrivedCountry && dispatch(setCountry(retrivedCountry));
        }
        if (insuredName) {
            dispatch(setNameInsured(insuredName));
        }
        if (retrievedDesc != '') {
            dispatch(setDescription(retrievedDesc));
        }
        else {
            dispatch(setDescription(''));
        }
    };
    useEffect(() => {
        if (isLinkedPage && !dataMapped && retrivedSubmissionData.submissionId != 0) {
            mapRetrivedSubmission();
            setdataMapped(true);
        }
    }, [retrivedSubmissionData.submissionId]);
    useEffect(() => {
        const retrivedBrokerName = findLabelById(brokerNameOptions, brokerId);
        //console.log("brokerNameOptions: ",brokerNameOptions)
        //console.log("retrivedbrokerId: ",brokerId)
        if (retrivedSubmissionData.submissionId > 0 && retrivedBrokerName) {
            dispatch(setBrokerNames(retrivedBrokerName));
            dispatch(setBrokerId(brokerId));
        }
        else {
            dispatch(setBrokerNames(""));
            dispatch(setBrokerId(0));
        }
    }, [brokerId]);
    useEffect(() => {
        const retrivedClientName = findLabelById(clientNameOptions, clientId);
        if (retrivedSubmissionData.submissionId > 0 && retrivedClientName) {
            dispatch(setClientName(retrivedClientName));
            dispatch(setClientId(clientId));
        }
    }, [clientId]);
    useEffect(() => {
        if (retrivedSubmissionData.submissionId > 0 && retrievedDesc) {
            dispatch(setDescription(retrievedDesc));
        }
        else {
            dispatch(setDescription(''));
        }
    }, [retrievedDesc]);
    useEffect(() => {
        const retrivedReInsContractType = findLabelById(reInsContractOptions, reinsuranceContractType);
        if (retrivedSubmissionData.submissionId > 0 && retrivedReInsContractType) {
            dispatch(setReInsContractType(retrivedReInsContractType));
        }
        else {
            dispatch(setReInsContractType(""));
        }
    }, [reinsuranceContractType]);
    const themehook = useTheme();
    return (_jsxs(BoxStyled, Object.assign({ sx: {
            padding: '20px 0px 20px 0px', color: '#00003f'
        } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', height: '24px', paddingBottom: '32px' } }, { children: _jsx(TypographyAtom, Object.assign({ sx: Object.assign({}, themehook.customStyles.sectionTitleStyle) }, { children: "Business & Client Overview" })) })), !isCollapsed &&
                _jsxs(BoxStyled, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', paddingRight: '20px', color: 'black' } }, { children: [_jsx(TextFieldComponent, { value: country, label: 'Country', dataArrDropdown: countryOptions, isRequired: true, id: 'country', labelId: "country-label", width: '300px', isDisabled: true }), _jsx(TextFieldComponent, { value: reInsContractType, label: 'Reinsurance Contract Type', dataArrDropdown: reInsContractOptions, isRequired: true, id: 'contract-type', labelId: "contract-type-label", width: '300px', isDisabled: true })] })), _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexWrap: 'wrap' } }, { children: [_jsx(TextFieldComponent, { value: brokerName, label: 'Broker Name', dataArrDropdown: brokerNameOptions, id: 'broker-name', labelId: "broker-name-label", isDisabled: true, width: '300px' }), _jsx(TextFieldComponent, { value: clientName, label: 'Client Name', dataArrDropdown: clientNameOptions, id: 'client-name', labelId: "client-name-label", isDisabled: true, width: '300px' }), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center" } }, { children: _jsx(TextFieldComponent, { id: 'number-insured', label: "Named Insured", isRequired: true, value: nameInsured, isDisabled: true, customInpStyle: {
                                            style: { padding: "9px 13px 9px 10px", width: "100%", backgroundColor: "#E5E5E5" }
                                        } }) })), _jsx(TextFieldComponent, { label: "Description", value: description, width: '300px', isDisabled: true, customInpStyle: {
                                        style: { padding: "9px 13px 9px 10px", width: "100%", backgroundColor: "#E5E5E5" }
                                    } })] }))] })] })));
};
