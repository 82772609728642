import { enGB, enUS, cy, de, fr, es, hr, hu, ja, pl, ptBR, zhCN, } from 'date-fns/locale';
import { getCurrentLanguageForDates } from '../translations/i18n';
import moment from 'moment-timezone';
// ----------------------------------
// TODO: Temp added. To be removed when local settings are added
const timezone = 'UTC';
// ----------------------------------
export var DateType;
(function (DateType) {
    DateType[DateType["Date"] = 0] = "Date";
    DateType[DateType["DateTime"] = 1] = "DateTime";
})(DateType || (DateType = {}));
export default function formatDate(date, type) {
    if (date === null) {
        return null;
    }
    const newDate = new Date(date);
    const _format = "MM/DD/YYYY";
    const _timezone = "America/New_York"; //EST FORMAT
    switch (type) {
        case DateType.Date: {
            if (!isNaN(newDate.getTime())) {
                return moment(date).tz(_timezone).format(_format);
            }
        }
        case DateType.DateTime: {
            return newDate.toLocaleString(getCurrentLanguageForDates(), {
                timeZone: _timezone,
            });
        }
        default: {
            return moment(date).tz(_timezone).format(_format);
        }
    }
}
export function formatDateToLocaleString(date) {
    const newDate = new Date(date);
    return newDate.toLocaleDateString(getCurrentLanguageForDates(), {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
}
/**
 * Checks if a date string parses to a date with a valid time
 * @param date The date string to check
 * @returns Boolean representing validity
 */
export function checkIsDateValid(date) {
    const dateObj = new Date(date);
    if (!isNaN(dateObj.getTime())) {
        return true;
    }
    return false;
}
/**
 * Converts selected date to avoid change in date while making api request by adding Timezone offset to selected Local time.
 * @param date selected date by date picker.
 * @returns modified date with added TimeZone offset.
 */
export function getConvertedLocalDate(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}
/**
 * Converts a date into an ISO string in the format YYYY-MM-DDTHH:MM:SS notably without and ignoring the timezone.
 * i.e. if the date provided was 2023-01-10T10:00:00 BST (+1) then the string returned will be "2023-01-10T10:00:00" with no timezone information.
 * @param date The date to convert.
 * @returns An ISO string representation of the provided date or an empty string if date is invalid.
 */
export function getLocalDateAsUTC(date) {
    if (!date || isNaN(new Date(date).getTime())) {
        return '';
    }
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    let dateString = newDate.toISOString();
    dateString = dateString.substring(0, dateString.indexOf('.'));
    return dateString;
}
export function getCurrentDateLocale() {
    switch (getCurrentLanguageForDates()) {
        case 'cy':
            return cy;
        case 'de':
            return de;
        case 'en-US':
            return enUS;
        case 'es':
            return es;
        case 'fr':
            return fr;
        case 'hr':
            return hr;
        case 'hu':
            return hu;
        case 'ja':
            return ja;
        case 'pl-PL':
            return pl;
        case 'pt-BR':
            return ptBR;
        case 'zh-CN':
            return zhCN;
        default:
            return enGB;
    }
}
export function isDateLessThan(date1, date2) {
    if (date1 && date2) {
        // could be Date or UTC string
        const compDate1 = new Date(date1).setHours(0, 0, 0, 0);
        const compDate2 = new Date(date2).setHours(0, 0, 0, 0);
        return compDate1 < compDate2;
    }
    return false;
}
