import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    perilsCoveredData: {
        fireOnly: false,
        boilerAndMachinery: false,
        wind: false,
        floodIncSurge: false,
        floodExcSurge: false,
        fireOnlyVal: 0,
        boilerAndMachineryVal: 0,
        windVal: 0,
        floodIncSurgeVal: 0,
        floodExcSurgeVal: 0
    }
};
const pearlsCoveredSlice = createSlice({
    name: 'pearlsCoveredDetails',
    initialState,
    reducers: {
        setPerilDetails: (state, action) => {
            state.perilsCoveredData = Object.assign(Object.assign({}, state.perilsCoveredData), action.payload);
        },
        clearPerilDetails: (state) => {
            state.perilsCoveredData = initialState.perilsCoveredData;
        },
        setPerilsCoveredDetailsBind: (state, action) => {
            var _a;
            //console.log("setPerilsCoveredDetailsBind: ",action.payload);
            let _propertySubmissionInfo = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.propertySubmission;
            state.perilsCoveredData.fireOnly = _propertySubmissionInfo.isFirePeril;
            state.perilsCoveredData.boilerAndMachinery = _propertySubmissionInfo.isBoilerMachineryPeril;
            state.perilsCoveredData.wind = _propertySubmissionInfo.isWindPeril;
            state.perilsCoveredData.floodIncSurge = _propertySubmissionInfo.isFloodIncSurgePeril;
            state.perilsCoveredData.floodExcSurge = _propertySubmissionInfo.isFloodExclSurgePeril;
            state.perilsCoveredData.fireOnlyVal = _propertySubmissionInfo.sublimitForFirePeril;
            state.perilsCoveredData.boilerAndMachineryVal = _propertySubmissionInfo.sublimitForBoilerMachineryPeril;
            state.perilsCoveredData.windVal = _propertySubmissionInfo.sublimitForWindPeril;
            state.perilsCoveredData.floodIncSurgeVal = _propertySubmissionInfo.sublimitForFloodIncSurgePeril;
            state.perilsCoveredData.floodExcSurgeVal = _propertySubmissionInfo.sublimitForFloodExclSurgePeril;
        }
    },
});
export const { setPerilDetails, clearPerilDetails, setPerilsCoveredDetailsBind } = pearlsCoveredSlice.actions;
export default pearlsCoveredSlice.reducer;
