import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BoxStyled from "../../../@atoms/layout/box/index.atom";
import { TypographyAtom, useTheme } from '@epam/reactjs-common';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateComponent from "../../Date/Date.component";
import { setClaimValuationDate, setLargeIndividualLoss, setNumberOfLossesOverTBD, setNumberOfYearsReported } from "../../../store/slices/lossInfoReducer";
import TextFieldComponent from "../../TextField/textField.Component";
import { appendDollar } from "../../../utils/currency.util";
import { findLabelById } from "../../../utils/refDataFilters/refDataFilters";
export const LossInfoContent = () => {
    var _a, _b, _c, _d, _e;
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const yearsVal = useSelector((state) => { var _a, _b; return (_b = (_a = state.lossInfo) === null || _a === void 0 ? void 0 : _a.lossOfInfoData) === null || _b === void 0 ? void 0 : _b.numberOfYearsReported; });
    const lossVal = useSelector((state) => { var _a, _b; return (_b = (_a = state.lossInfo) === null || _a === void 0 ? void 0 : _a.lossOfInfoData) === null || _b === void 0 ? void 0 : _b.numberOfLossesOverTBD; });
    const individualLossVal = useSelector((state) => { var _a, _b; return (_b = (_a = state.lossInfo) === null || _a === void 0 ? void 0 : _a.lossOfInfoData) === null || _b === void 0 ? void 0 : _b.largeIndividualLoss; });
    const cliamVal = useSelector((state) => { var _a, _b; return (_b = (_a = state.lossInfo) === null || _a === void 0 ? void 0 : _a.lossOfInfoData) === null || _b === void 0 ? void 0 : _b.claimValuationDate; });
    const themehook = useTheme();
    const dataArrDropdown = [
        { id: 0, label: "Select" },
        { id: 1, label: "1" },
        { id: 2, label: "2" },
        { id: 3, label: "3" },
        { id: 4, label: "4" },
        { id: 5, label: "5" },
        { id: 6, label: "6" },
        { id: 7, label: "7" },
        { id: 8, label: "8" },
        { id: 9, label: "9" },
        { id: 10, label: "10" },
    ];
    const isLinkedPage = useSelector((state) => { var _a; return (_a = state.isLinked) === null || _a === void 0 ? void 0 : _a.isLinkedPage; });
    let retrivedSubmissionData = useSelector((state) => { var _a, _b; return (_b = (_a = state.retrivedSubmissionDetils) === null || _a === void 0 ? void 0 : _a.retrivedSubmissionData) === null || _b === void 0 ? void 0 : _b.submissionPayload; });
    const [dataMapped, setdataMapped] = useState(false);
    const retrivednumberOfYearsReported = (_a = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission) === null || _a === void 0 ? void 0 : _a.numberOfYearsReported;
    const retrivednumberOfLossesOverTBD = (_b = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission) === null || _b === void 0 ? void 0 : _b.numberOfLossesOverTBD;
    const retrivedlargeIndividualLoss = (_c = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission) === null || _c === void 0 ? void 0 : _c.largeIndividualLoss;
    const retrivedclaimValuationDate = (_e = (_d = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission) === null || _d === void 0 ? void 0 : _d.claimValuationDate) === null || _e === void 0 ? void 0 : _e.split('T')[0];
    const mapRetrivedSubmission = () => {
        if (retrivednumberOfYearsReported) {
            const retrivednumberOfYearsReportedLabel = findLabelById(dataArrDropdown, retrivednumberOfYearsReported);
            retrivednumberOfYearsReportedLabel && dispatch(setNumberOfYearsReported(retrivednumberOfYearsReportedLabel));
            //id needs to be sent then oncomment next line
            // retrivedLOBType && dispatch(setLobId(retrivednumberOfYearsReportedLabel!))
        }
        if (retrivednumberOfLossesOverTBD) {
            dispatch(setNumberOfLossesOverTBD(retrivednumberOfLossesOverTBD));
        }
        if (retrivedlargeIndividualLoss) {
            dispatch(setLargeIndividualLoss(retrivedlargeIndividualLoss));
        }
        if (retrivedclaimValuationDate) {
            dispatch(setClaimValuationDate(retrivedclaimValuationDate));
        }
    };
    useEffect(() => {
        if (isLinkedPage && !dataMapped && (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId) != 0) {
            mapRetrivedSubmission();
            setdataMapped(true);
        }
    }, [retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId]);
    const handleClaimValuationDateChange = (value) => {
        dispatch(setClaimValuationDate(value));
    };
    return (_jsxs(BoxStyled, Object.assign({ sx: {
            padding: '20px 0px 20px 0px', color: '#00003f'
        } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', height: '24px', paddingBottom: '32px' } }, { children: _jsx(TypographyAtom, Object.assign({ sx: Object.assign({}, themehook.customStyles.sectionTitleStyle) }, { children: "Loss Information" })) })), !isCollapsed &&
                _jsx(_Fragment, { children: _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexWrap: 'wrap' } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center" } }, { children: _jsx(TextFieldComponent, { id: 'yearsVal', label: "Number of Years Reported ", value: yearsVal, isDisabled: true, isRequired: true, customInpStyle: {
                                        style: { padding: "9px 13px 9px 10px", width: "100%", backgroundColor: "#E5E5E5" }
                                    } }) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center" } }, { children: _jsx(TextFieldComponent, { label: "Number of losses over $250,000", isRequired: true, customInpStyle: {
                                        style: { padding: "9px 13px 9px 10px", width: "100%", backgroundColor: "#E5E5E5" }
                                    }, value: lossVal, isDisabled: true }) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center" } }, { children: _jsx(TextFieldComponent, { label: "Largest Individual Loss", isRequired: true, customInpStyle: {
                                        style: { padding: "9px 13px 9px 10px", width: "100%", backgroundColor: "#E5E5E5" }
                                    }, value: appendDollar(individualLossVal), isDisabled: true }) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center", marginTop: "11px" } }, { children: _jsx(DateComponent, { value: cliamVal, label: 'Claim Valuation Date', isRequired: true, dataArrDropdown: [], handleDateChange: handleClaimValuationDateChange, id: 'cliamVal', labelId: "cliamVal", isDisabled: true, width: '300px' }) }))] })) })] })));
};
