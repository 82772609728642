import { useDispatch } from "react-redux";
import { clearSubmissionDetails } from "../store/slices/businessOverviewReducer";
import { clearCommissionDetails } from "../store/slices/commissionReducer";
import { clearLayersRequestedDetails } from "../store/slices/layersRequestedReducer";
import { clearLocationsDetails } from "../store/slices/locationAddressReducer";
import { clearLossInfo } from "../store/slices/lossInfoReducer";
import { clearPerilDetails } from "../store/slices/pearlsCoveredReducer";
import { clearPolicyInfo } from "../store/slices/policyInfoReducer";
import { clearDatesDetails } from "../store/slices/submisisonDateInfoReducer";
export const useClearFormData = () => {
    const dispatch = useDispatch();
    const clearFormData = () => {
        dispatch(clearSubmissionDetails());
        dispatch(clearLocationsDetails());
        dispatch(clearDatesDetails());
        dispatch(clearPerilDetails());
        dispatch(clearPolicyInfo());
        dispatch(clearLayersRequestedDetails());
        dispatch(clearCommissionDetails());
        dispatch(clearLossInfo());
    };
    return clearFormData;
};
