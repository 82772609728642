import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    policyInfowData: {
        lob: '',
        lobId: '',
        paperCompany: '',
        paperCompanyId: '',
        policyNumber: '',
    }
};
const policyInfoSlice = createSlice({
    name: 'policyInfoDetails',
    initialState,
    reducers: {
        setLob: (state, action) => {
            state.policyInfowData.lob = action.payload;
        },
        setPaperCompany: (state, action) => {
            state.policyInfowData.paperCompany = action.payload;
        },
        setLobId: (state, action) => {
            state.policyInfowData.lobId = action.payload;
        },
        setPaperCompanyId: (state, action) => {
            state.policyInfowData.paperCompanyId = action.payload;
        },
        setPolicyNum: (state, action) => {
            state.policyInfowData.policyNumber = action.payload;
        },
        clearPolicyInfo: (state) => {
            state.policyInfowData = initialState.policyInfowData;
        },
        setPolicyInfo: (state, action) => {
            //console.log("setPolicyInfo: ",action.payload);
            var _a, _b, _c;
            let _propertySubmissionInfo = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.propertySubmission;
            if (((_b = action.payload) === null || _b === void 0 ? void 0 : _b.submissionLOBs.length) > 0) {
                state.policyInfowData.lobId = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.submissionLOBs[0].lobType.trim();
            }
            state.policyInfowData.paperCompany = _propertySubmissionInfo.paperCompany;
            state.policyInfowData.paperCompanyId = _propertySubmissionInfo.paperCompany;
            state.policyInfowData.policyNumber = _propertySubmissionInfo.clientPolicyNumber;
        }
    },
});
export const { setLob, setPaperCompany, setLobId, setPaperCompanyId, setPolicyNum, clearPolicyInfo, setPolicyInfo } = policyInfoSlice.actions;
export default policyInfoSlice.reducer;
