var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import DialogAtom from '../dialog/index.atom';
import DialogActionsAtom from '@epam/reactjs-common/atoms/layout/DialogActions';
import DialogContentAtom from '@epam/reactjs-common/atoms/layout/DialogContent';
import DialogTitleAtom from '@epam/reactjs-common/atoms/layout/DialogTitle';
import TypographyAtom from '@epam/reactjs-common/atoms/content/Typography';
import { useTheme } from '@epam/reactjs-common';
import CloseIconWhite from '../../../assets/icon/CloseIconWhite.svg';
import BoxStyled from '../box/index.atom';
import TextFieldComponent from '../../../components/TextField/textField.Component';
import "./index.style.css";
const EmailDialogAtom = (props) => {
    const { title, to, setTo, subject, setSubject, handleClose, alertMessage, dialogActions, dialogBody } = props, dialogProps = __rest(props, ["title", "to", "setTo", "subject", "setSubject", "handleClose", "alertMessage", "dialogActions", "dialogBody"]);
    const [attachment, setAttachment] = useState(null);
    const [recipient, setRecipient] = useState('');
    const handleFileChange = (event) => {
        setAttachment(event.target.files[0]);
        console.log("attachment attachment :", attachment);
    };
    const FileUpload = ({ onFileChange }) => {
        return (_jsx("input", { type: "file", onChange: onFileChange }));
    };
    const handleSubjectChange = (value) => {
        // let capitalizedText = capitalizeSentence(value)
        setSubject(value);
    };
    const handleToChange = (value) => {
        setTo(value);
    };
    const themehook = useTheme();
    return (_jsx(DialogAtom, Object.assign({ id: "email-wrapper", apType: "userForm" }, dialogProps, { children: _jsxs(BoxStyled
        // height='750px'
        , { children: [title && (_jsxs(DialogTitleAtom, Object.assign({ style: { display: 'flex', justifyContent: 'space-between', backgroundColor: '#0A3699', height: '60px' } }, { children: [_jsx(TypographyAtom, Object.assign({ variant: "h4", color: "#fff", fontSize: '14px', padding: '15px' }, { children: title })), _jsxs(TypographyAtom, Object.assign({ variant: "title", component: "p", sx: { marginTop: '-4px', fontSize: '0.2rem', cursor: 'pointer' }, onClick: handleClose }, { children: [" ", _jsx(CloseIconWhite, { sx: { height: '8px', width: '8px', color: '#061C49', opacity: '0.7' } })] }))] }))), _jsxs(DialogContentAtom, { children: [_jsxs(BoxStyled, Object.assign({ style: { display: 'flex', alignItems: 'center', padding: '5px 0px' } }, { children: [_jsx(TypographyAtom, Object.assign({ variant: "label1", width: '13%', style: Object.assign({ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }, themehook.customStyles.labelStyle) }, { children: "To : " })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center", width: '87%' } }, { children: _jsx(TextFieldComponent, { id: "emailto", handleTextChange: (e) => { handleToChange(e.target.value); }, value: to, width: '100%' }) }))] })), _jsxs(BoxStyled, Object.assign({ style: { display: 'flex', alignItems: 'center', padding: '5px 0px' } }, { children: [_jsx(TypographyAtom, Object.assign({ variant: "label1", width: '13%', style: Object.assign({ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }, themehook.customStyles.labelStyle) }, { children: "Subject : " })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center", width: '87%' } }, { children: _jsx(TextFieldComponent, { id: 'subject', handleTextChange: (e) => { handleSubjectChange((e.target.value)); }, value: subject, width: '100%' }) }))] })), _jsx(BoxStyled, { children: dialogBody })] }), _jsx(DialogActionsAtom, { children: dialogActions })] }) })));
};
export default EmailDialogAtom;
