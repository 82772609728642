import { useSelector } from "react-redux";
import { filterByRefType } from "../../utils/refDataFilters/refDataFilters";
import { RoleCodeEnum } from "../../utils/enum/RoleCodeEnum";
const filterPaperData = (papervalue, paperdata) => {
    if (paperdata === null || papervalue === null) {
        return [];
    }
    else {
        const paperCompanyIds = papervalue.split(',');
        const paperCompaniesOptions = paperdata === null || paperdata === void 0 ? void 0 : paperdata.filter(item => paperCompanyIds.includes(item.id));
        return paperCompaniesOptions;
    }
};
const getPaperCompany = (brokerCedent, clientName, role) => {
    if (role === RoleCodeEnum.Broker) {
        const filteredCedent = brokerCedent === null || brokerCedent === void 0 ? void 0 : brokerCedent.find((cedent) => cedent.clientName === clientName);
        if (filteredCedent) {
            return filteredCedent.paperCompany;
        }
    }
    else if (role === RoleCodeEnum.SuperUser || role === RoleCodeEnum.Underwriter) {
        const filteredCedent = brokerCedent === null || brokerCedent === void 0 ? void 0 : brokerCedent.find((cedent) => cedent.cedentOrgUnit.cedentName === clientName);
        if (filteredCedent) {
            return filteredCedent.cedentOrgUnit.paperCompany;
        }
    }
    const filteredCedent = brokerCedent === null || brokerCedent === void 0 ? void 0 : brokerCedent.find((cedent) => cedent.cedentOrgUnit.cedentName === clientName);
    if (filteredCedent) {
        return filteredCedent.cedentOrgUnit.paperCompany;
    }
    return null;
};
export const extractPaperCompanyData = () => {
    const paperCompanyRefData = filterByRefType("PaperCompany");
    const profileData = useSelector((state) => state.userProfileData.userProfileData);
    if ((profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode) === RoleCodeEnum.Client) {
        const paperCompanyProfileOptions = useSelector((state) => state.userProfileData.userProfileData).orgUnit.paperCompany;
        const paperCompanyOptions = filterPaperData(paperCompanyProfileOptions, paperCompanyRefData);
        return paperCompanyOptions;
    }
    else if ((profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode) === RoleCodeEnum.Broker) {
        const clientName = useSelector((state) => state.businessOv.businessOverviewData.clientName);
        if (clientName !== '') {
            const paperCompanyProfileOptions = getPaperCompany(profileData === null || profileData === void 0 ? void 0 : profileData.brokerCedent, clientName, profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode);
            const paperCompanyOptions = filterPaperData(paperCompanyProfileOptions, paperCompanyRefData);
            return paperCompanyOptions;
        }
        else {
            return [{ id: '', label: '' }];
        }
    }
    else if ((profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode) === RoleCodeEnum.SuperUser || profileData.individual.roleCode === RoleCodeEnum.Underwriter) {
        const clientName = useSelector((state) => state.businessOv.businessOverviewData.clientName);
        if (clientName !== '') {
            const paperCompanyProfileOptions = getPaperCompany(profileData === null || profileData === void 0 ? void 0 : profileData.cedentOrgUnitList, clientName, profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode);
            const paperCompanyOptions = filterPaperData(paperCompanyProfileOptions, paperCompanyRefData);
            return paperCompanyOptions;
        }
        else {
            return [{ id: '', label: '' }];
        }
    }
    else {
        return [];
    }
};
