import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isSideNavOpen: true,
};
const sideNavOpenCloseReducer = createSlice({
    name: 'sideNavOpenCloseReducer',
    initialState,
    reducers: {
        setSideNavOpen: (state) => {
            state.isSideNavOpen = true;
        },
        setSideNavClose: (state) => {
            state.isSideNavOpen = false;
        },
    },
});
export const { setSideNavOpen, setSideNavClose } = sideNavOpenCloseReducer.actions;
export default sideNavOpenCloseReducer.reducer;
