var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export default (_a) => {
    var _b;
    var props = __rest(_a, []);
    const { unit, space, size, palette, shadows, customPalette } = props.theme;
    return `
      .MuiBackdrop-root {
        background-color:${customPalette.grey.disabledTransparent};
      }
      & .MuiDialogTitle-root {
        display:flex;
        justify-content:space-between;
        & .MuiIconButton-root{
          width:${size(2)}px;
          height:${size(2)}px; 
          border-radius:${unit(2)}px;
          margin-left:${space(1)}px;
          &:hover{
            background-color:${customPalette.primary.activeTransparent}
          }
          & .MuiSvgIcon-root{
            width:${size(2)}px;
            height:${size(2)}px; 
            color:${palette.primary.main}; 
          }
        }
      }
      &[apType="form"],
      &[apType="settingsForm"],
      &[apType="userForm"],
      &[apType="webhookForm"],
      &[apType="mapGroupForm"],
      &[apType="logoUpload"]{
        .MuiDialogTitle-root{
          display: flex;
          align-items: center;
          background-color:${customPalette.grey.hover};
          padding: ${size(1)}px ${size(5)}px ${size(1)}px ${size(2)}px;
          height: ${size(5)}px;
        }
      }
      &[apType="form"],
      &[apType="settingsForm"],      
      &[apType="logoUpload"]{
        .MuiPaper-elevation24 {
          box-shadow: ${shadows[2]};
          border: 1px solid ${customPalette.grey.border};
        }
        .MuiDialogContent-root{
          height: calc(${unit(75)}vh - ${size(12.5) - 1}px);
          padding: ${size(0)}px;
        }
        .MuiInputBase-root:has(input:not(textarea)){
          height: ${size(5.5)}px;
        }
        .MuiOutlinedInput-root .MuiAutocomplete-input{
          top: -${space(0.625)}px;
          position: relative;
        }
        .MuiTextField-root{
          .MuiInputLabel-shrink{
            top: ${space(0)}px !important;
          }
          .MuiInputLabel-root{
              top: -${space(0.625)}px;
          }
        }
        .MuiCheckbox-root{
            padding: ${space(0)}px ${space(1.125)}px;
        }
        .MuiFormHelperText-root{
          height: 0;
          margin-top: 0;
        }
        .Mui-disabled{
          .MuiOutlinedInput-notchedOutline{
          border: 1px solid ${customPalette.grey.dragged}
          }
        }
        .MuiGrid-container{
          & .MuiGrid-item{
            .MuiList-root {
              margin-left:-${size(2)}px;   
              margin-top:-${size(1)}px;
            }
          }
        }
        //Overriding the paper-root margin of Alert in form dialog
        .MuiAlert-root{
          margin: ${size(0)}px;
        }
        .MuiDialogActions-root{
          padding: ${space(3)}px;
          height: ${size(7.5)}px;
          margin-right:${space(1)}px;
          .MuiButton-root{
            margin-right:${space(1)}px;
          }
        }
        .MuiDialog-paperWidthSm{
          min-width: ${unit(60)}vw;
          min-height: ${unit(75)}vh;
        }
        .MuiDivider-root{
          background-color: ${customPalette.grey.border}
        }
        .MuiDialogContent-dividers{
          border-top: 1px solid ${customPalette.grey.border};
          border-bottom: 1px solid ${customPalette.grey.border};
        }
      }
      &[apType="settingsForm"]{
        .MuiDialog-paperWidthSm{
          min-width:  ${unit(80)}vw;
          min-height:  ${unit(80)}vh;
        }
        & .MuiDialogTitle-root{
          display: flex;
          width:${unit(100)}%;
          > .MuiIconButton-root {
            border-radius:${unit(2)}px;
            margin: auto ${space(0)}px auto auto;
            padding:${space(1)}px;
            &:hover {
              background-color: ${(_b = palette.action) === null || _b === void 0 ? void 0 : _b.hover};
            }
            > svg {
              width:${size(1.5)}px;
              height:${size(1.5)}px;
              fill:${palette.primary.main};    
            }
          }
        }
      }
      &[apType="alert"]{
        .MuiDialog-paperWidthSm{
          min-width: 30vw;
        } 
      }
      &[apType="webhookForm"]{
        .MuiDialog-paper{
          max-width: ${unit(30)}vw;
        }
      }
      &[apType="userForm"],
      &[apType="webhookForm"]{
        .form-container{
          padding:0px;
          margin:0px;
        }
        .grid-container{
          padding:${space(1)}px ${space(2)}px;
        }
        .MuiDialogContent-root{
          min-height: calc(${unit(50)}vh - ${size(12.5) - 1}px);
        }
        
        .MuiDialogActions-root{
          padding: ${space(3)}px;
          height: ${size(7.5)}px;
          margin-right:${space(1)}px;
          .MuiButton-root{
            margin-right:${space(1)}px;
          }
        }
      }
      
      &[apType="mapGroupForm"]
      {
        .form-container{
          padding:0px;
          margin:0px;
        }
        .grid-container{
          padding:${space(1)}px ${space(2)}px;
        }
        .MuiDialogContent-root{
          min-height: calc(${unit(50)}vh - ${size(12.5) - 1}px);
        }
        & .MuiDialogTitle-root {
        & .MuiIconButton-root{
            & .MuiSvgIcon-root{
              width:${size(2)}px;
              height:${size(2)}px; 
              color:${palette.grey[700]};
            }
          }
        }
      }
      &[apType="logoUpload"]{
        .logo-content {
          width: 100%;
          display: flex;
          min-height: ${size(25)}px;
          justify-content: center;
          padding: ${space(3)}px;

          .image-cropper{
            width: 100%;
            height: 100%;
            overflow: visible;
          }

          .cropper-point{
            background-color: ${palette.primary.main};
          }

          .cropper-line{
            background-color: ${palette.primary.main};
          }

          .point-nw{
            opacity: 1;
            background-color: white;
            height: ${size(3)}px;
            width: ${size(3)}px;
            border: 1px solid ${palette.primary.main};
            border-radius: ${size(3)}px;
            left: -${size(1.5)}px;
            top: -${size(1.5)}px;
          }

          .point-ne{
            opacity: 1;
            background-color: white;
            height: ${size(3)}px;
            width: ${size(3)}px;
            border: 1px solid ${palette.primary.main};
            border-radius: ${size(3)}px;
            right: -${size(1.5)}px;
            top: -${size(1.5)}px;
          }

          .point-se{
            opacity: 1;
            background-color: white;
            height: ${size(3)}px;
            width: ${size(3)}px;
            border: 1px solid ${palette.primary.main};
            border-radius: ${size(3)}px;
            bottom: -${size(1.5)}px;
            right: -${size(1.5)}px;
          }

          .point-sw{
            opacity: 1;
            background-color: white;
            height: ${size(3)}px;
            width: ${size(3)}px;
            border: 1px solid ${palette.primary.main};
            border-radius: ${size(3)}px;
            bottom: -${size(1.5)}px;
            left: -${size(1.5)}px;
          }

          .logo-content-paper{
            width: 100%;
            position: relative;
          }

          .preview-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0;
          }

          .preview-paper{
            justify-content: center;
            max-width: ${size(40)}px;
            min-width: ${size(35)}px;
            display: flex;
            flex-direction: column;
            align-self: center;
          }

          .preview-dashed-border{
            border: 1px dashed ${customPalette.primary.dark}
          }
      }
    `;
};
