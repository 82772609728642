import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component 
import { useSelector } from 'react-redux';
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId, getLoginUserRole } from '../../utils/CommonUtilties';
import { GridPageSizeConstantForDashboard, getAgGridWidth } from '../../utils/constants/Global.Constant';
import Loader from '../../@atoms/content/loader/loader.atom';
import { SubmissionPages } from '../../utils/enum/SubmissionPage';
import { GET_Method, headers } from '../../components/customhooks/useAxios';
import '../../assets/styles/custom-aggrid.css';
import './underWriter.css';
const UWDashboardGridComponent = React.memo(function UWDashboardGridComponent(props) {
    /* START _ Hooks */
    const locationParams = useLocation();
    const navigate = useNavigate();
    const [userProfileId, setUserProfileId] = useState(getLoginUserId());
    const [roleCode, setRoleCode] = useState(getLoginUserRole());
    const gridRef = useRef(null);
    const isSideNavOpen = useSelector((state) => state.sideNavOpen.isSideNavOpen);
    const [gridWidth, setgridWidth] = useState("474px");
    const [submissionList, setSubmissionList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    /* END _ Hooks */
    /* Variables */
    const submission_API_URL = process.env.REACT_APP_SUBMISSION_API_URL;
    //console.log("props?.type: ",props?.type);
    // console.log("props?.colDefs ",props?.colDefs)
    /*1: My Referals, 2: All Referals - Referred Status  3:All Referals:'Approved/Declined/Quoted  */
    useEffect(() => {
        var _a;
        setIsLoading(true);
        const axiosParams = {
            url: `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Submissions?PersonId=${userProfileId}&RoleCode=${roleCode}&PageNumber=${1}&PageSize=${GridPageSizeConstantForDashboard}&SubmissionPage=${SubmissionPages.UWReferralListForDashboard}&type=${props.type}`,
            methodType: "get",
            headers: headers
        };
        GET_Method(axiosParams.url, axiosParams).then((response) => {
            //console.log("UW - Referal List -response.data: ", response.data);
            setSubmissionList(response.data);
            setIsLoading(false);
        }).catch((error) => {
            //console.log("Getting error while retriving submission UW Referral details ",error);
        });
        (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.api.showNoRowsOverlay();
    }, [props === null || props === void 0 ? void 0 : props.type]);
    /* END - API Calls */
    useEffect(() => {
        getAgGridWidth(locationParams, isSideNavOpen, setgridWidth);
    }, [isSideNavOpen]);
    /* END - Functions */
    return (_jsxs(_Fragment, { children: [isLoading ? _jsx(Loader, {}) : null, (props === null || props === void 0 ? void 0 : props.colDefs) &&
                (submissionList === null || submissionList === void 0 ? void 0 : submissionList.status) === 0 ?
                _jsx("div", Object.assign({ className: "ag-theme-quartz" // applying the grid theme
                    , style: { height: 435 } }, { children: _jsx(AgGridReact, { defaultColDef: {
                            resizable: true,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            flex: 1,
                            minWidth: 120,
                            suppressMovable: true,
                        }, rowData: (submissionList === null || submissionList === void 0 ? void 0 : submissionList.submissionReferralAssignment) === null ? [] : submissionList === null || submissionList === void 0 ? void 0 : submissionList.submissionReferralAssignment, columnDefs: props === null || props === void 0 ? void 0 : props.colDefs, overlayNoRowsTemplate: submissionList === null || submissionList === void 0 ? void 0 : submissionList.message }) })) : _jsx(_Fragment, { children: submissionList === null || submissionList === void 0 ? void 0 : submissionList.message })] }));
});
export default UWDashboardGridComponent;
