import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import '../../assets/styles/custom-aggrid.css';
import { headers, useAxios } from '../customhooks/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import formatDate, { DateType } from '../../utils/date.util';
import { currencyFormat } from '../../utils/currency.util';
import { AppRoutesConstants } from '../../utils/constants/app_routes_data';
import { GridPageSizeConstantForDashboard, getAgGridWidth } from '../../utils/constants/Global.Constant';
import { useSelector } from 'react-redux';
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId, getLoginUserRole } from '../../utils/CommonUtilties';
import { useDispatch } from 'react-redux';
import { clearRetrivedSubmissionDetails } from '../../store/slices/retrivedSubmissionReducer';
import { setSubmissionId, setSubmissionStatus } from '../../store/slices/submissionIdReducer';
import { setIsLinkedPage } from '../../store/slices/isLinkedPageReducer';
import { storeUWRuleMessagesAndPricing } from '../../store/slices/coherentQuoteDetailsReducer';
import { SubmissionStatusEnum } from '../../utils/enum/SubmissionStatusEnum';
import { SubmissionPages } from '../../utils/enum/SubmissionPage';
import Drafticon from '../../assets/imgs/draft.svg';
import Quotedicon from '../../assets/imgs/quoted.svg';
import Subjectreferralicon from '../../assets/imgs/subjectreferral.svg';
import Declinedicon from '../../assets/imgs/declined.svg';
import BoundIcon from '../../assets/imgs/BoundIcon.svg';
import ExpiredIcon from '../../assets/icon/ExpiredIcon.svg';
import ReferralsIcondashboard from '../../assets/imgs/ReferralsIcondashboard.svg';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
export default function SubmissionsListDashboardComponent(props) {
    const locationParams = useLocation();
    const navigate = useNavigate();
    const [useProfileId, setUserProfileId] = useState(getLoginUserId());
    const [roleCode, setroleCode] = useState(getLoginUserRole());
    const gridRef = useRef(null);
    const isSideNavOpen = useSelector((state) => state.sideNavOpen.isSideNavOpen);
    const [gridWidth, setgridWidth] = useState("474px");
    const dispatch = useDispatch();
    const submission_API_URL = getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl);
    const redirectComponent = (params) => {
        let _sId = params.data.submissionId;
        if (_sId === undefined)
            _sId = 0;
        dispatch(clearRetrivedSubmissionDetails());
        dispatch(setSubmissionId(_sId));
        dispatch(setIsLinkedPage(true));
        dispatch(setSubmissionStatus(params.data.submissionStatus));
        dispatch(storeUWRuleMessagesAndPricing({
            coherentRuleMessages: params.data.coherentRuleMessages,
            coherentPricing: params.data.coherentPricing,
            submissionStatus: params.data.submissionStatus
        }));
        if (params.data.submissionStatus === SubmissionStatusEnum.SUBJECTTOREFERRAL
            || params.data.submissionStatus === SubmissionStatusEnum.REFERRED) {
            navigate(AppRoutesConstants.SubmissionReferred + "" + locationParams.search);
            return;
        }
        else if (params.data.submissionStatus === SubmissionStatusEnum.DECLINED
            || params.data.submissionStatus === SubmissionStatusEnum.QUOTED
            || params.data.submissionStatus === SubmissionStatusEnum.APPROVED) {
            navigate(AppRoutesConstants.SubmissionResult + "" + locationParams.search);
        }
        else if (params.data.submissionStatus === SubmissionStatusEnum.BOUNDED) {
            navigate(AppRoutesConstants.SubmissionBound + "" + locationParams.search);
        }
        else {
            navigate(AppRoutesConstants.SubmissionRequest + "" + locationParams.search);
        }
    };
    const getStatusIcon = (submissionStatus, lastQuotedExpiryDate) => {
        let icon;
        submissionStatus = submissionStatus.toLowerCase();
        if (submissionStatus === SubmissionStatusEnum.DRAFT.toLowerCase())
            icon = _jsx(Drafticon, {});
        else if ((submissionStatus === SubmissionStatusEnum.QUOTED.toLowerCase() || submissionStatus === SubmissionStatusEnum.APPROVED.toLowerCase()) && lastQuotedExpiryDate && new Date(lastQuotedExpiryDate) < new Date()) //EXPIRED
            icon = _jsx(ExpiredIcon, {});
        else if (submissionStatus === SubmissionStatusEnum.QUOTED.toLowerCase())
            icon = _jsx(Quotedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.SUBJECTTOREFERRAL.toLowerCase())
            icon = _jsx(Subjectreferralicon, {});
        else if (submissionStatus === SubmissionStatusEnum.DECLINED.toLowerCase())
            icon = _jsx(Declinedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.APPROVED.toLowerCase())
            icon = _jsx(Quotedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.BOUNDED.toLowerCase())
            icon = _jsx(BoundIcon, {});
        else if (submissionStatus === SubmissionStatusEnum.REFERRED.toLowerCase())
            icon = _jsx(ReferralsIcondashboard, {});
        return icon;
    };
    const [colDefs, setColDefs] = useState([
        {
            headerName: "Named Insured",
            field: "insuredName",
            width: props.Sortable ? "238px" : "250px",
            tooltipValueGetter: (params) => params.value,
            cellStyle: { borderRight: '1px solid lightgray' },
            sortable: props.Sortable,
            //pinned: 'left',
            cellRenderer: function (params) {
                return _jsx("a", Object.assign({ onClick: () => redirectComponent(params) }, { children: params.data.insuredName }));
            },
        },
        {
            headerName: "EverTech Reference #", field: "submissionLOB.UWSSubmissionId", width: "150px",
            sortable: false,
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                var _a;
                return params.data.uwsSubmissionId ? (_a = params.data) === null || _a === void 0 ? void 0 : _a.uwsSubmissionId.slice(0, -2) : '';
            }
        },
        {
            headerName: "Description", field: "description",
            sortable: false,
            tooltipValueGetter: (params) => params.value,
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                var _a;
                return (_a = params.data) === null || _a === void 0 ? void 0 : _a.description;
            }
        },
        {
            headerName: "Paper Company", field: "paperCompany", width: "90px",
            cellStyle: { borderRight: '1px solid lightgray' },
            flex: 0.5,
            cellRenderer: function (params) {
                return params.data.propertySubmissionDto.paperCompany;
            }
        },
        {
            headerName: "Reinsurance Effective Date", field: "reinsuranceEffectiveDate", width: "80px", wrapText: true,
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return formatDate(params.data.propertySubmissionDto.reinsuranceEffectiveDate, DateType.Date);
            }
        },
        {
            headerName: "Quoted Premium", field: "propertySubmissionDto.annualGrossPremium", width: "30px",
            sortable: props.Sortable,
            cellStyle: { borderRight: '1px solid lightgray' },
            wrapText: false,
            cellRenderer: (params) => {
                var _a, _b;
                const lastQuotedExpiryDate = (_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.lastQuotedExpiryDate;
                let isExpired = false;
                if (lastQuotedExpiryDate) {
                    const expiryDate = new Date(lastQuotedExpiryDate);
                    const currentDate = new Date();
                    if (expiryDate < currentDate) {
                        isExpired = true;
                    }
                }
                if (params.data.submissionStatus.toLowerCase() === "draft"
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.DRAFT.toLowerCase()
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.SUBJECTTOREFERRAL.toLowerCase()
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.DECLINED.toLowerCase()
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.REFERRED.toLowerCase()
                    || isExpired)
                    return "";
                else
                    return currencyFormat((_b = params.data.propertySubmissionDto) === null || _b === void 0 ? void 0 : _b.annualGrossPremium);
            }
        },
        {
            headerName: "Status",
            field: "status", width: "140px", flex: 1.2,
            sortable: props.Sortable,
            cellRenderer: function (params) {
                return getStatusIcon(params.data.submissionStatus);
            }
        },
    ]);
    const axiosParams = {
        url: `${submission_API_URL}/Submissions?PersonId=${useProfileId}&RoleCode=${roleCode}&PageNumber=${1}&PageSize=${GridPageSizeConstantForDashboard}&SubmissionPage=${SubmissionPages.SubmissionDashBoardList}`,
        methodType: "get",
        headers: headers
    };
    var { response, error, loading } = useAxios(axiosParams);
    const [responsedata, setresponsedata] = useState();
    useEffect(() => {
        var _a;
        if (response) {
            setresponsedata(response);
        }
        else {
        }
        (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.api.showNoRowsOverlay();
    }, [response]);
    const gridOptions = {
        getRowStyle: (params) => {
            if (params.node.rowIndex % 2 != 0) {
                return { background: '#F5F5F5' };
            }
        },
    };
    useEffect(() => {
        getAgGridWidth(locationParams, isSideNavOpen, setgridWidth);
    }, [isSideNavOpen]);
    return (_jsx(_Fragment, { children: (responsedata === null || responsedata === void 0 ? void 0 : responsedata.status) === 200 ?
            _jsx("div", Object.assign({ className: "ag-theme-quartz", style: { height: 429 } }, { children: _jsx(AgGridReact, { defaultColDef: {
                        resizable: true,
                        wrapHeaderText: false,
                        autoHeaderHeight: true,
                        flex: 1,
                        minWidth: 140,
                        suppressMovable: true,
                    }, rowData: responsedata === null || responsedata === void 0 ? void 0 : responsedata.submissions, columnDefs: colDefs, overlayNoRowsTemplate: responsedata === null || responsedata === void 0 ? void 0 : responsedata.message }) })) : _jsx(_Fragment, { children: responsedata === null || responsedata === void 0 ? void 0 : responsedata.message }) }));
}
