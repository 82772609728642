import { createSlice } from '@reduxjs/toolkit';
const coherentDataSlice = createSlice({
    name: 'coherentQuoteDetails',
    initialState: {
        coherentRuleMessages: [],
        coherentPricing: {
            "annualGrossPremium": 0,
            "commission": 0,
            "brokerage": 0,
            "netAnnualPremium": 0,
            "termPremium": 0
        },
        submissionStatus: ''
    },
    reducers: {
        storeUWRuleMessagesAndPricing: (state, action) => {
            state.coherentRuleMessages = action.payload.coherentRuleMessages;
            state.coherentPricing = action.payload.coherentPricing;
            state.submissionStatus = action.payload.submissionStatus;
        },
    },
});
export const { storeUWRuleMessagesAndPricing } = coherentDataSlice.actions;
export default coherentDataSlice.reducer;
