import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import BoxStyled from '../../../@atoms/layout/box/index.atom';
import { ButtonAtom, DialogAtom, DialogActionsAtom, DialogContentAtom, TypographyAtom } from "@epam/reactjs-common";
import ApproveIcon from '../../../assets/icon/ApproveCheckboxIcon.svg';
const SuccessPopUp = ({ open, onClose, onGoToHome }) => {
    return (_jsxs(DialogAtom, Object.assign({ open: open, sx: {
            padding: "2em",
            ".MuiDialog-paper": {
                maxWidth: "unset"
            }
        } }, { children: [_jsx(DialogContentAtom, Object.assign({ sx: { pr: 4, pl: 4, pb: 3 } }, { children: _jsxs(BoxStyled, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(ApproveIcon, {}), _jsxs("div", Object.assign({ style: { marginTop: "3%" } }, { children: [_jsx(TypographyAtom, Object.assign({ sx: { mt: '2px', ml: '5px', fontSize: "12px" } }, { children: "You have successfully approved the referral." })), _jsx(TypographyAtom, Object.assign({ sx: { mt: '2px', ml: '5px', fontSize: "12px" } }, { children: "An email will be sent to Broker/Client." }))] }))] })) })), _jsx(DialogActionsAtom, Object.assign({ sx: { justifyContent: "center", pb: 4 } }, { children: _jsxs(ButtonAtom, Object.assign({ variant: "contained", color: "primary", onClick: onGoToHome, sx: { width: "280px", mr: 2, ml: 2, padding: "2px 2px", fontWeight: "bold", borderRadius: "0px", border: "1px solid #215EF7" } }, { children: [_jsx("span", Object.assign({ className: "arrow-icon" }, { children: "\u2190" })), " Go to Home"] })) }))] })));
};
export default SuccessPopUp;
