import { useSelector } from "react-redux";
import { RoleCodeEnum } from "../../utils/enum/RoleCodeEnum";
const removeDuplicates = (array, key) => {
    const uniqueKeys = new Set();
    return array.filter((item) => {
        const value = item[key];
        if (uniqueKeys.has(value)) {
            return false;
        }
        uniqueKeys.add(value);
        return true;
    });
};
const filterByClientName = (brokerCedent, clientName) => {
    if (clientName === '') {
        return brokerCedent;
    }
    return brokerCedent.filter((item) => item.clientName === clientName);
};
export const extractBrokerData = () => {
    var _a;
    const profileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    if (profileData === undefined) {
        return [{
                id: '',
                label: ''
            }];
    }
    if (profileData.individual.roleCode === RoleCodeEnum.Broker) {
        return [{
                label: profileData.orgUnit.orgUnitName,
                id: (_a = profileData.orgUnit.orgUnitId) === null || _a === void 0 ? void 0 : _a.toString()
            }];
    }
    else if (profileData.individual.roleCode === RoleCodeEnum.Client) {
        if ((profileData === null || profileData === void 0 ? void 0 : profileData.brokerCedent.length) !== 0) {
            let brokerArr = profileData.brokerCedent.map((broker) => ({
                label: broker.brokerName,
                id: broker.brokerid.toString(),
            }));
            brokerArr = removeDuplicates(brokerArr, 'id');
            brokerArr.push({ id: null, label: 'Direct' });
            return brokerArr;
        }
        else {
            return [{
                    id: null,
                    label: 'Direct'
                }];
        }
    }
    else if (profileData.individual.roleCode === RoleCodeEnum.SuperUser) {
        if ((profileData === null || profileData === void 0 ? void 0 : profileData.brokerCedent.length) !== 0) {
            const clientName = useSelector((state) => state.businessOv.businessOverviewData.clientName);
            const filteredBrokerCedent = filterByClientName(profileData.brokerCedent, clientName);
            let brokerArr = filteredBrokerCedent.map((broker) => ({
                label: broker.brokerName,
                id: broker.brokerid.toString(),
            }));
            brokerArr = removeDuplicates(brokerArr, 'id');
            brokerArr.push({ id: null, label: 'Direct' });
            return brokerArr;
        }
        else {
            return [{
                    id: null,
                    label: 'Direct'
                }];
        }
    }
    else if (profileData.individual.roleCode === RoleCodeEnum.Underwriter) {
        if ((profileData === null || profileData === void 0 ? void 0 : profileData.brokerCedent.length) !== 0) {
            const clientName = useSelector((state) => state.businessOv.businessOverviewData.clientName);
            const filteredBrokerCedent = profileData.brokerCedent;
            let brokerArr = filteredBrokerCedent.map((broker) => ({
                label: broker.brokerName,
                id: broker.brokerid.toString(),
            }));
            brokerArr = removeDuplicates(brokerArr, 'id');
            brokerArr.push({ id: null, label: 'Direct' });
            return brokerArr;
        }
        else {
            return [{
                    id: null,
                    label: 'Direct'
                }];
        }
    }
    else {
        return [{
                id: '',
                label: ''
            }];
    }
};
