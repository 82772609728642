import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    referredPreminumData: {
        uWAdjAmt: 10,
        premAdjType: null,
        premAdjTypeCode: null,
        premAdjValue: 0,
        internalUWNotes: null,
        termClientOfferAmt: 0,
        annualizedClientOfferAmt: 0,
        isPremiumUpdated: false,
        isCalculated: false,
        lastQuotedDate: undefined,
        lastQuotedPremium: undefined,
        annulizedGrossSharePct: 0,
        termPremium: undefined,
        termNetPremium: undefined,
        commissionCedeAmt: undefined,
        commissionBrokerageAmt: undefined,
        attritionalELR: undefined,
        netCommissionTermPremium: undefined,
        systemCalculatedAmt: undefined,
        underWriterName: undefined
    }
};
const underWriterReferredReducerSlice = createSlice({
    name: 'underWriterReferredDetails',
    initialState,
    reducers: {
        setUnderWriterReferredDetails: (state, action) => {
            state.referredPreminumData = Object.assign(Object.assign({}, state.referredPreminumData), action.payload);
        },
        clearUnderWriterReferredDetails: (state) => {
            state.referredPreminumData = initialState.referredPreminumData;
        },
        setPreminumAdjTypeId: (state, action) => {
            state.referredPreminumData.premAdjType = action.payload;
        },
        setUnderWriterName: (state, action) => {
            state.referredPreminumData.underWriterName = action.payload;
        },
    },
});
export const { setUnderWriterReferredDetails, setPreminumAdjTypeId, clearUnderWriterReferredDetails, setUnderWriterName } = underWriterReferredReducerSlice.actions;
export default underWriterReferredReducerSlice.reducer;
