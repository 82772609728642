import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputAdornmentAtom, TypographyAtom, useTheme } from '@epam/reactjs-common';
import BoxStyled from '../../@atoms/layout/box/index.atom';
import NumberFieldOutlined from '../../@atoms/form/control/numberfield/index.atom';
import "./inputField.Component.css";
import TextFieldOutlined from '../../@atoms/form/control/textfield/index.atom';
const formatNumberWithCommas = (num) => {
    return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
// Function to convert shorthand notation to full numerical value
const convertShorthandToNumber = (input) => {
    if (input.endsWith('M') || input.endsWith('m')) {
        return parseFloat(input.slice(0, -1)) * 1000000;
    }
    if (input.endsWith('K') || input.endsWith('k')) {
        return parseFloat(input.slice(0, -1)) * 1000;
    }
    if (input.endsWith('B') || input.endsWith('b')) {
        return parseFloat(input.slice(0, -1)) * 1000000000;
    }
    return parseFloat(input);
};
export default function InputFieldComponent(props) {
    const { id, isDecimalAllowed, outlineClass, labelMarginBottom, label, width, isRequired, onChange, onBlur, placeholder, customInpStyle = {}, sx = {}, disabled, value, isCurrency = false, currencySymbol, isPercentage, leftPaddingNone, wrapperPadding } = props;
    const themehook = useTheme();
    const handleChange = (event) => {
        let val = event.target.value.replace(/,/g, '');
        if (val === '') {
            onChange({ target: { value: '' } }); // Pass empty value if input is empty
            return;
        }
        // Check if the first character is a number (0-9)
        if (!/^[0-9]/.test(val)) {
            return; // Exit if the first character is not a number
        }
        const regexNumber = /^[0-9]*[mbkMBK]?$/;
        const regexDecimal = /^\d*(?:\.\d*)?$/;
        const regex = isDecimalAllowed ? regexDecimal : regexNumber;
        if (regex.test(val)) {
            val = val.replace(/^0*(?!0)(\d+)/, '$1');
            let eventObj = { target: { value: val } };
            onChange(Object.assign({}, eventObj));
        }
    };
    const handleBlur = (event) => {
        const inputValue = event.target.value;
        // Check if input is not empty before conversion
        if (inputValue.trim() === '') {
            let eventObj = { target: { value: '' } }; // Keep it blank
            onBlur ? onBlur(eventObj) : onChange(eventObj);
            return;
        }
        // Convert shorthand notation to full number
        const numericValue = convertShorthandToNumber(inputValue.replace(/,/g, ''));
        // Format numeric value with commas for display, ensuring it's a valid number
        const formattedValue = isNaN(numericValue) ? '' : formatNumberWithCommas(numericValue);
        let eventObj = { target: { value: formattedValue } };
        onBlur ? onBlur(eventObj) : onChange(eventObj);
    };
    return (_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignSelf: 'center', width: width ? width : '300px', padding: wrapperPadding ? wrapperPadding : leftPaddingNone ? "10px 1rem 10px 0px" : '10px' } }, { children: [_jsxs(BoxStyled, Object.assign({ className: 'input-field-label', sx: { display: 'flex', flexDirection: 'row', marginBottom: labelMarginBottom ? labelMarginBottom : isCurrency ? '11px' : '11px' } }, { children: [_jsx(TypographyAtom, Object.assign({ sx: Object.assign({}, themehook.customStyles.labelStyle), style: {} }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                            fontSize: '12px', color: 'red', paddingLeft: '5px'
                        }, component: "p" }, { children: "*" })))] })), _jsx(BoxStyled, Object.assign({ className: outlineClass }, { children: isCurrency ?
                    _jsx(TextFieldOutlined, { id: id, onChange: handleChange, onBlur: handleBlur, disabled: disabled, value: isPercentage ? value : formatNumberWithCommas(value !== null && value !== void 0 ? value : ""), className: isPercentage ? "ispercentage currency-field-container" : "isdollar currency-field-container", inputProps: customInpStyle, InputProps: isPercentage ?
                            { endAdornment: _jsx(InputAdornmentAtom, Object.assign({ position: "end" }, { children: currencySymbol })) }
                            : { startAdornment: _jsx(InputAdornmentAtom, Object.assign({ position: "start" }, { children: currencySymbol })) } })
                    : _jsx(NumberFieldOutlined, { className: "text-field-container", id: id, placeholder: placeholder, inputProps: customInpStyle, onChange: handleChange, onBlur: handleBlur, sx: sx, disabled: disabled, value: value }) }))] })));
}
