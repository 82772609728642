import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    orgUnitDataData: {
        orgUnitId: 0,
        orgCity: '',
        orgPostalCode: '',
        orgClientPartyId: Number(''),
        orgStdComm: '',
        orgState: '',
        orgStateCode: '',
        orgLob: '',
        orgLobCode: '',
        orgStreetAddress: '',
        orgCountry: '',
        orgCountryCode: '',
        orgCountryId: '',
        orgUnitType: '',
        orgUnitName: '',
        orgPaperCompany: '',
        orgUnitEffectiveDate: '',
        orgUnitExpirationDate: '',
        orgPaperCompanyList: [],
    }
};
const adminOrgUnitSlice = createSlice({
    name: 'adminOrgUnitDetails',
    initialState,
    reducers: {
        clearAdminOrgUnitDetails: () => {
            return initialState;
        },
        setOrgUnitDetails: (state, action) => {
            return action.payload;
        },
        setOrgCity: (state, action) => {
            state.orgUnitDataData.orgCity = action.payload;
        },
        setOrgState: (state, action) => {
            state.orgUnitDataData.orgState = action.payload;
        },
        setOrgStateCode: (state, action) => {
            state.orgUnitDataData.orgStateCode = action.payload;
        },
        setOrgLob: (state, action) => {
            state.orgUnitDataData.orgLob = action.payload;
        },
        setOrgLobCode: (state, action) => {
            state.orgUnitDataData.orgLobCode = action.payload;
        },
        setOrgPostalCode: (state, action) => {
            state.orgUnitDataData.orgPostalCode = action.payload;
        },
        setOrgCountry: (state, action) => {
            state.orgUnitDataData.orgCountry = action.payload;
        },
        setOrgCountryCode: (state, action) => {
            state.orgUnitDataData.orgCountryCode = action.payload;
        },
        setOrgStreetAddress: (state, action) => {
            state.orgUnitDataData.orgStreetAddress = action.payload;
        },
        setOrgClientPartyId: (state, action) => {
            state.orgUnitDataData.orgClientPartyId = action.payload;
        },
        setStdComm: (state, action) => {
            state.orgUnitDataData.orgStdComm = action.payload;
        },
        setOrgUnitName: (state, action) => {
            state.orgUnitDataData.orgUnitName = action.payload;
        },
        setOrgPaperCompany: (state, action) => {
            state.orgUnitDataData.orgPaperCompany = action.payload;
        },
        setOrgUnitType: (state, action) => {
            state.orgUnitDataData.orgUnitType = action.payload;
        },
        setPaperCompanyList: (state, action) => {
            state.orgUnitDataData.orgPaperCompanyList = action.payload;
        },
        setCountryId: (state, action) => {
            state.orgUnitDataData.orgCountryId = action.payload;
        },
        setOrgUnitEffectiveDate: (state, action) => {
            state.orgUnitDataData.orgUnitEffectiveDate = action.payload;
        },
        setOrgUnitExpirationDate: (state, action) => {
            state.orgUnitDataData.orgUnitExpirationDate = action.payload;
        },
        setAdminOrgUnit: (state, action) => {
            var _a, _b, _c;
            let _orgUnitInfo = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.orgUnitDetailsList[0];
            state.orgUnitDataData.orgClientPartyId = _orgUnitInfo.clientPartyId;
            state.orgUnitDataData.orgCity = _orgUnitInfo.city;
            state.orgUnitDataData.orgCountryCode = _orgUnitInfo.countryCode;
            state.orgUnitDataData.orgStateCode = _orgUnitInfo.stateProvinceCode;
            state.orgUnitDataData.orgUnitType = _orgUnitInfo.orgTypeCode;
            state.orgUnitDataData.orgUnitId = _orgUnitInfo.orgUnitId;
            state.orgUnitDataData.orgUnitName = _orgUnitInfo.orgUnitName;
            state.orgUnitDataData.orgPaperCompany = _orgUnitInfo.paperCompanies;
            state.orgUnitDataData.orgPostalCode = _orgUnitInfo.postalCode;
            state.orgUnitDataData.orgStreetAddress = _orgUnitInfo.addressLine1;
            state.orgUnitDataData.orgUnitEffectiveDate = (_b = _orgUnitInfo.effectiveDate) === null || _b === void 0 ? void 0 : _b.slice(0, 10);
            state.orgUnitDataData.orgUnitExpirationDate = (_c = _orgUnitInfo.expirationDate) === null || _c === void 0 ? void 0 : _c.slice(0, 10);
        },
    },
});
export const { setOrgCountry, setOrgCity, setOrgClientPartyId, setOrgPostalCode, setOrgState, setOrgStreetAddress, setOrgUnitName, setStdComm, setOrgStateCode, setOrgCountryCode, setOrgUnitDetails, setOrgLob, setOrgLobCode, setOrgUnitType, setPaperCompanyList, setCountryId, clearAdminOrgUnitDetails, setAdminOrgUnit, setOrgPaperCompany, setOrgUnitEffectiveDate, setOrgUnitExpirationDate } = adminOrgUnitSlice.actions;
export default adminOrgUnitSlice.reducer;
