var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import axios from 'axios';
import { generateGUID } from '../../utils/CommonUtilties';
export const headers = {
    "X-EVE-ORIGINATOR": process.env.REACT_APP_Google_API_X_EVE_ORIGINATOR || '',
    "X-EVE-CORRELATIONID": generateGUID() || '',
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": '*',
};
export const esbHeaders = {
    "X-EVE-ORIGINATOR": process.env.REACT_APP_Google_API_X_EVE_ORIGINATOR || '',
    "X-EVE-CORRELATIONID": generateGUID() || '',
    'Content-Type': 'multipart/form-data',
    "Access-Control-Allow-Origin": '*',
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type, Authorization"
};
export const useAxios = (requestParams) => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const authHeaders = Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` });
            const axiosParams = {
                url: requestParams.url,
                method: requestParams.methodType,
                data: requestParams.body,
                params: requestParams.param || null,
                headers: authHeaders,
            };
            const result = yield axios.request(axiosParams);
            setResponse(result.data);
        }
        catch (error) {
            setError(error);
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        fetchData();
    }, []); // execute once only
    return { response, error, loading };
};
export const GET_Method = (url, requestParams) => {
    const authHeaders = Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` });
    const axiosParams = {
        url: requestParams.url,
        method: requestParams.methodType,
        data: requestParams.body,
        headers: authHeaders,
    };
    return axios.request(axiosParams);
};
export const POST_Method = (requestParams, additionalHeaders) => {
    const authHeaders = Object.assign(Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` }), additionalHeaders);
    let axiosParams = {
        url: requestParams.url,
        method: requestParams.methodType,
        data: requestParams.body,
        headers: authHeaders
    };
    return axios.request(axiosParams);
};
export const Blob_POST_Method = (requestParams) => {
    const authHeaders = Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` });
    let axiosParams = {
        url: requestParams.url,
        method: requestParams.methodType,
        data: requestParams.body,
        headers: authHeaders,
        responseType: 'arraybuffer'
    };
    return axios.request(axiosParams);
};
export const ESB_POST_Method = (requestParams) => {
    const authHeaders = Object.assign(Object.assign({}, esbHeaders), { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` });
    let axiosParams = {
        url: requestParams.url,
        method: requestParams.methodType,
        data: requestParams.body,
        headers: authHeaders
    };
    return axios.post(requestParams.url, requestParams.body, axiosParams);
};
export const PUT_Method = (requestParams) => {
    const authHeaders = Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` });
    let axiosParams = {
        url: requestParams.url,
        method: requestParams.methodType,
        data: requestParams.body,
        headers: authHeaders
    };
    return axios.request(axiosParams);
};
