import { useSelector } from "react-redux";
import { RoleCodeEnum } from "../../utils/enum/RoleCodeEnum";
const removeDuplicates = (array, key) => {
    const uniqueKeys = new Set();
    return array.filter(item => {
        const value = item[key];
        if (uniqueKeys.has(value)) {
            return false;
        }
        uniqueKeys.add(value);
        return true;
    });
};
const filterByBrokerName = (brokerCedent, brokerName) => {
    return brokerCedent.filter(item => item.brokerName === brokerName);
};
const getAllCedentNames = (cedentOrgUnitList) => {
    return cedentOrgUnitList.map(client => ({
        label: client.cedentOrgUnit.cedentName,
        id: client.cedentOrgUnit.cedentId
    }));
};
export const extractClientData = () => {
    var _a, _b;
    const profileData = useSelector((state) => state.userProfileData.userProfileData);
    const businessOverviewData = useSelector((state) => state.businessOv.businessOverviewData);
    if (profileData === undefined) {
        return [{
                id: '',
                label: ''
            }];
    }
    if ((profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode) === RoleCodeEnum.Client) {
        return [{
                label: profileData.orgUnit.orgUnitName,
                id: (_a = profileData.orgUnit.orgUnitId) === null || _a === void 0 ? void 0 : _a.toString()
            }];
    }
    else if ((profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode) === RoleCodeEnum.Broker) {
        let clientArr = (_b = profileData === null || profileData === void 0 ? void 0 : profileData.brokerCedent) === null || _b === void 0 ? void 0 : _b.map(client => ({
            label: client.clientName,
            id: client.cedentId.toString(),
        }));
        clientArr = removeDuplicates(clientArr, 'id');
        return clientArr;
    }
    else if ((profileData === null || profileData === void 0 ? void 0 : profileData.individual.roleCode) === RoleCodeEnum.SuperUser) {
        const brokerName = useSelector((state) => state.businessOv.businessOverviewData.brokerName);
        const cedentId = businessOverviewData.clientId;
        const filteredBrokerCedent = filterByBrokerName(profileData.brokerCedent, brokerName);
        if (brokerName === 'Direct') {
            return getAllCedentNames(profileData.cedentOrgUnitList);
        }
        let clientArr = filteredBrokerCedent.map((client) => ({
            label: client.clientName,
            id: client.cedentId.toString(),
        }));
        clientArr = removeDuplicates(clientArr, 'id');
        return clientArr;
    }
    else if (profileData.individual.roleCode === RoleCodeEnum.Underwriter) {
        const brokerName = useSelector((state) => state.businessOv.businessOverviewData.brokerName);
        const filteredBrokerCedent = profileData.brokerCedent;
        const cedentId = businessOverviewData === null || businessOverviewData === void 0 ? void 0 : businessOverviewData.clientId;
        if (brokerName === 'Direct') {
            return getAllCedentNames(profileData.cedentOrgUnitList);
        }
        let clientArr = filteredBrokerCedent.map((client) => ({
            label: client.clientName,
            id: client.cedentId.toString(),
        }));
        clientArr = removeDuplicates(clientArr, 'id');
        return clientArr;
    }
    else {
        return [{
                id: '',
                label: ''
            }];
    }
};
