import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationENUS from './en-US/translation.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationENUS,
    },
    'en-US': {
        translation: translationENUS,
    },
};
export const getCurrentLanguage = () => {
    //This is the language currently being used for translations.
    //i.e. if the user asks for a language we don't have, this will be the tenant default as it couldn't resolve the requested language.
    return i18n.resolvedLanguage;
};
export const getCurrentLanguageForDates = () => {
    //Used to sidestep the issue with 'en' resulting in US dates without having to redo languages from the start.
    return i18n.resolvedLanguage == 'en' ? 'en-GB' : i18n.resolvedLanguage;
};
export const getCurrentLanguageForMap = () => {
    //Uses different language codes - Not all are supported by here map tiles API so default to english if no match.
    //Returning 'cy' as welsh labels for testing visibility
    switch (getCurrentLanguage()) {
        case 'cy':
            return 'wel';
        case 'de':
            return 'ger';
        case 'es':
            return 'spa';
        case 'fr':
            return 'fre';
        case 'pl-PL':
            return 'pol';
        case 'pt-BR':
            return 'por';
        case 'zh-CN':
            return 'chi';
        default:
            return 'eng';
    }
};
//Will need expanding with other language translations once available
export const getCurrentAgGridTranslations = () => {
    const lang = getCurrentLanguage();
    switch (lang) {
        case 'en-US':
            return translationENUS.AGGRID;
        default:
            return translationENUS.AGGRID;
    }
};
//NOTE: This is currently updated with adjusted fallbacks in src/layout/index.tsx once we have access to tenant data.
use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
    fallbackLng: 'en',
    debug: true,
    resources,
    //Use this to override language. "cimode" will show the keys instead of translations.
    //lng: 'en',
    saveMissing: true,
    nonExplicitSupportedLngs: true,
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    //Get language from browser settings only
    detection: { order: ['navigator'] },
});
export default i18n;
