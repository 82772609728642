import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import MainApp from './MainApp';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
const container = document.getElementById('root');
const root = createRoot(container);
// console.log(process.env.REACT_APP_ENV)
// if (process.env.REACT_APP_ENV === 'dev') {
//   require('dotenv').config({ path: '.env.dev' });
// } else if (process.env.REACT_APP_ENV === 'qa') {
//   require('dotenv').config({ path: '.env.qa' });
// } else if (process.env.REACT_APP_ENV === 'staging') {
//   require('dotenv').config({ path: '.env.staging' });
// } else if (process.env.REACT_APP_ENV === 'prod') {
//   require('dotenv').config({ path: '.env.prod' });
// } else {
//   throw new Error('Unknown environment specified');
// }
// console.log("ENV Loading....",process.env.REACT_APP_BASE_URL)
root.render(_jsx(Provider, Object.assign({ store: store }, { children: _jsx(PersistGate, Object.assign({ loading: null, persistor: persistor }, { children: _jsx(BrowserRouter, { children: _jsx(MainApp, {}) }) })) })));
