import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    loctionaddressDetailsData: [],
};
const loctionAddressDetailsSlice = createSlice({
    name: 'loctionAddressDetails',
    initialState,
    reducers: {
        setLoctionAddressDetails: (state, action) => {
            state.loctionaddressDetailsData = action.payload;
        },
        clearLocationsDetails: () => {
            return initialState;
        }
    },
});
export const { setLoctionAddressDetails, clearLocationsDetails } = loctionAddressDetailsSlice.actions;
export default loctionAddressDetailsSlice.reducer;
