import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BoxStyled from "../../../@atoms/layout/box/index.atom";
import { CheckboxAtom, TypographyAtom, useTheme } from '@epam/reactjs-common';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { setPerilDetails } from "../../../store/slices/submissionDetailsReducer";
import { setPerilDetails } from "../../../store/slices/pearlsCoveredReducer";
import TextFieldComponent from "../../../components/TextField/textField.Component";
import { appendDollar } from "../../../utils/currency.util";
export const PearlsCoveredContent = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { perilsCoveredData } = useSelector(({ pearlsCoveredDetails }) => pearlsCoveredDetails);
    const fireOnlyVal = useSelector((state) => state.pearlsCoveredDetails.perilsCoveredData.fireOnly);
    const boilerAndMachineryVal = useSelector((state) => state.pearlsCoveredDetails.perilsCoveredData.boilerAndMachineryVal);
    const fireOnwIndVallyVal = useSelector((state) => state.pearlsCoveredDetails.perilsCoveredData.floodIncSurgeVal);
    const floodExcSurgeVal = useSelector((state) => state.pearlsCoveredDetails.perilsCoveredData.floodExcSurgeVal);
    const themehook = useTheme();
    const dispatch = useDispatch();
    const CHECKBOX_CONSTANTS = [
        {
            label: "All Risk",
            stateMappedKey: "fireOnly"
        },
        {
            label: "Boiler and Machinery",
            stateMappedKey: "boilerAndMachinery"
        },
        {
            label: "Wind excl Surge",
            stateMappedKey: "wind"
        },
        {
            label: "Zone X Flood excl Surge",
            stateMappedKey: "floodExcSurge"
        },
    ];
    let retrivedSubmissionData = useSelector((state) => state.retrivedSubmissionDetils.retrivedSubmissionData.submissionPayload);
    const isLinkedPage = useSelector((state) => state.isLinked.isLinkedPage);
    const [dataMapped, setdataMapped] = useState(false);
    //console.log("retrivedSubmissionData - pearls",retrivedSubmissionData)
    const retrivedisFirePeril = retrivedSubmissionData.propertySubmission.isFirePeril;
    const retrivedisBoilerMachineryPeril = retrivedSubmissionData.propertySubmission.isBoilerMachineryPeril;
    const retrivedisWindPeril = retrivedSubmissionData.propertySubmission.isWindPeril;
    const retrivedisFloodIncSurgePeril = retrivedSubmissionData.propertySubmission.isFloodIncSurgePeril;
    const retrivedisFloodExclSurgePeril = retrivedSubmissionData.propertySubmission.isFloodExclSurgePeril;
    const retrivedsublimitForFirePeril = retrivedSubmissionData.propertySubmission.sublimitForFirePeril;
    const retrivedsublimitForBoilerMachineryPeril = retrivedSubmissionData.propertySubmission.sublimitForBoilerMachineryPeril;
    const retrivedsublimitForWindPeril = retrivedSubmissionData.propertySubmission.sublimitForWindPeril;
    const retrivedsublimitForFloodIncSurgePeril = retrivedSubmissionData.propertySubmission.sublimitForFloodIncSurgePeril;
    const retrivedsublimitForFloodExclSurgePeril = retrivedSubmissionData.propertySubmission.sublimitForFloodExclSurgePeril;
    const mapRetrivedSubmission = () => {
        if (retrivedsublimitForFirePeril) {
            dispatch(setPerilDetails({ fireOnlyVal: retrivedsublimitForFirePeril }));
        }
        if (retrivedsublimitForWindPeril) {
            dispatch(setPerilDetails({ windVal: retrivedsublimitForWindPeril }));
        }
        if (retrivedsublimitForBoilerMachineryPeril) {
            dispatch(setPerilDetails({ boilerAndMachineryVal: retrivedsublimitForBoilerMachineryPeril }));
        }
        if (retrivedsublimitForFloodIncSurgePeril) {
            dispatch(setPerilDetails({ floodIncSurgeVal: retrivedsublimitForFloodIncSurgePeril }));
        }
        if (retrivedsublimitForFloodExclSurgePeril) {
            dispatch(setPerilDetails({ floodExcSurgeVal: retrivedsublimitForFloodExclSurgePeril }));
        }
        if (retrivedisFirePeril) {
            dispatch(setPerilDetails({ fireOnly: retrivedisFirePeril }));
        }
        if (retrivedisBoilerMachineryPeril) {
            dispatch(setPerilDetails({ boilerAndMachinery: retrivedisBoilerMachineryPeril }));
        }
        if (retrivedisWindPeril) {
            dispatch(setPerilDetails({ wind: retrivedisWindPeril }));
        }
        if (retrivedisFloodIncSurgePeril) {
            dispatch(setPerilDetails({ floodIncSurge: retrivedisFloodIncSurgePeril }));
        }
        if (retrivedisFloodExclSurgePeril) {
            dispatch(setPerilDetails({ floodExcSurge: retrivedisFloodExclSurgePeril }));
        }
    };
    useEffect(() => {
        if (isLinkedPage && !dataMapped && retrivedSubmissionData.submissionId != 0) {
            mapRetrivedSubmission();
            setdataMapped(true);
        }
    }, [retrivedSubmissionData.submissionId]);
    return (_jsxs(BoxStyled, Object.assign({ sx: {
            padding: '20px 0px 20px 0px', color: '#00003f', paddingBottom: '5px', paddingTop: '1px'
        } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', height: '24px', } }, { children: _jsx(TypographyAtom, Object.assign({ sx: Object.assign({}, themehook.customStyles.sectionTitleStyle) }, { children: "Perils Covered" })) })), !isCollapsed &&
                _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexWrap: 'wrap' } }, { children: CHECKBOX_CONSTANTS.map((peril) => {
                        let isDisabled = !perilsCoveredData[peril.stateMappedKey];
                        return (_jsxs(BoxStyled, Object.assign({ sx: { mt: 2 } }, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex' } }, { children: [_jsx(CheckboxAtom, { disabled: true, id: peril.stateMappedKey, checked: perilsCoveredData[`${peril.stateMappedKey}`], sx: {
                                                '& .MuiSvgIcon-root': {
                                                    fill: 'grey',
                                                    backgroundColor: 'initial',
                                                }
                                            } }), _jsx(TypographyAtom, Object.assign({ style: {
                                                fontSize: "10px", alignContent: "center",
                                                fontWeight: "bolder", color: "rgba(0,0,0,0.6)"
                                            } }, { children: peril.label }))] })), _jsx(BoxStyled, Object.assign({ sx: { display: 'flex', pl: "1px" } }, { children: _jsx(TextFieldComponent, { width: '145px', label: "Sublimit", placeholder: "--", isRequired: false, isDisabled: true, customInpStyle: {
                                            style: { padding: "3px 3px 3px 10px", backgroundColor: "#E5E5E5", color: '#000' }
                                        }, value: appendDollar(perilsCoveredData[`${peril.stateMappedKey}Val`] === 0 ? "" : perilsCoveredData[`${peril.stateMappedKey}Val`]), isCurrency: true, currencySymbol: "$", disabled: true }) }))] }), peril.label));
                    }) }))] })));
};
