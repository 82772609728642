import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import '../../assets/styles/dropdown.common.css';
import BoxStyled from '../../@atoms/layout/box/index.atom';
import { TypographyAtom, useTheme } from '@epam/reactjs-common';
const DropdownComponent = ({ id, label, labelId, dataArrDropdown, value = dataArrDropdown[0].label, isRequired = false, handleSelectChange, isDisabled = false, width, leftPaddingNone, isMarginLeft = false, wrapperPadding, labelWidth, fieldWidth, labelMarginBottom }) => {
    const themehook = useTheme();
    return (_jsxs(BoxStyled, Object.assign({ sx: { width: width,
            padding: wrapperPadding ? wrapperPadding : leftPaddingNone ? "10px 10px 0px 0px" : '10px 10px 0px 10px',
            marginTop: labelId === "policyType-label" ? "-15px" : "0px", marginLeft: isMarginLeft ? "10px" : "0px" } }, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', marginBottom: labelMarginBottom ? labelMarginBottom : '11px' } }, { children: [_jsx(TypographyAtom, Object.assign({ id: labelId, sx: Object.assign(Object.assign({}, themehook.customStyles.labelStyle), { width: labelWidth }) }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                            fontSize: '12px', color: 'red', paddingLeft: '5px'
                        }, component: "p" }, { children: "*" })))] })), _jsx(Select, Object.assign({ sx: { border: '1px solid #80808075', width: fieldWidth ? fieldWidth : (labelId === "declinedReason-label" ? "380px" : '100%') }, disabled: (dataArrDropdown === null || dataArrDropdown === void 0 ? void 0 : dataArrDropdown.length) === 1 ? true : isDisabled, labelId: labelId, id: id, value: (dataArrDropdown === null || dataArrDropdown === void 0 ? void 0 : dataArrDropdown.length) === 1 ? dataArrDropdown[0].label : value, label: label, onChange: (e) => handleSelectChange(e.target.value) }, { children: (dataArrDropdown === null || dataArrDropdown === void 0 ? void 0 : dataArrDropdown.length) > 0 ? (dataArrDropdown.map((item) => {
                    return _jsx(MenuItem, Object.assign({ value: item.label }, { children: item.label }), item.id);
                })) : null }))] })));
};
export default DropdownComponent;
