var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export default (_a) => {
    var props = __rest(_a, []);
    const { palette } = props.theme;
    return `
    .MuiCalendarPicker-root{

      .MuiPickersDay-root.MuiPickersDay-current{
        color:${palette.primary.main};
      }
      .MuiPickersDay-root.MuiPickersDay-today{
        color:  ${palette.primary.main};
        font-weight: 500;
      }
      .MuiPickersDay-root.Mui-selected{
        background-color:${palette.primary.dark};
        color: ${palette.common.white};
      }
      .MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected){
        border: none;
      }
      .MuiPickersDay-root.MuiPickersToolbar-toolbar{
          background-color:${palette.primary.main};
      }
    }
  `;
};
