var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export default (_a) => {
    var props = __rest(_a, []);
    const { palette, space, customPalette } = props.theme;
    return `
    .MuiAutocomplete-paper{
      margin-top:0px;
    }
    .MuiPopover-root,
    .MuiAutocomplete-popper{
      li {
        &.MuiAutocomplete-option.Mui-focused[aria-selected="true"]:hover, &.Mui-selected:hover{
         background-color: ${customPalette.primary.active};
      }
        &[data-focus="true"]{
          background-color: ${customPalette.grey.focus};
        }
        &:hover {
          background-color: ${customPalette.grey.hover};
        }
        &[aria-selected="true"]{
          background: ${customPalette.primary.selected};
        }
      }
    }
   .ApDropdownMenuItem.MuiMenuItem-root{
      padding: ${space(0.75)}px ${space(2)}px;
   }
   .ApInputLabel.Mui-focused{
      color: ${palette.primary.main};
   }

  `;
};
