// reducer.ts
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    emailAddress: '',
    individual: {
        personId: '',
        id: 0,
        orgUnitId: 0,
        roleCode: '',
        underWriterAssistantId: 0,
        partyId: 0,
        partyCode: '',
        partyName: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phone: '',
        title: '',
        statusCode: '',
        effectiveDate: '',
        expirationDate: '',
        createByUserId: 0,
        createTS: '',
        lastChgByUserId: 0,
        lastChgTS: '',
        brokerCedentPayload: [],
    },
    selectedOrgRoleCode: '',
    isCreateFlag: false
};
const individualDetails = createSlice({
    name: 'individualDetailsReducer',
    initialState,
    reducers: {
        clearIndividualDetails: () => {
            return initialState;
        },
        setIndividualDetails: (state, action) => {
            return action.payload;
        },
        setRoleCode: (state, action) => {
            state.individual.roleCode = action.payload;
        },
        setPartyId: (state, action) => {
            state.individual.partyId = action.payload;
        },
        setPartyCode: (state, action) => {
            state.individual.partyCode = action.payload;
        },
        setFirstName: (state, action) => {
            state.individual.firstName = action.payload;
        },
        setLastName: (state, action) => {
            state.individual.lastName = action.payload;
        },
        setEmailAddress: (state, action) => {
            state.individual.emailAddress = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.individual.phone = action.payload;
        },
        setPersonId: (state, action) => {
            state.individual.personId = action.payload;
        },
        setBrokerCedentName: (state, action) => {
            //state.ind = action.payload;
        },
        setEffectiveDate: (state, action) => {
            state.individual.effectiveDate = action.payload;
        },
        setExpirationDate: (state, action) => {
            state.individual.expirationDate = action.payload;
        },
        updateBrokerCedentPayload: (state, action) => {
            state.individual.brokerCedentPayload = action.payload;
        },
        setIsCreateFlag: (state, action) => {
            state.isCreateFlag = action.payload;
        },
        setOrgRoleCode: (state, action) => {
            state.selectedOrgRoleCode = action.payload;
        },
    },
});
export const { setOrgRoleCode, setEffectiveDate, setExpirationDate, setPersonId, setIsCreateFlag, setIndividualDetails, clearIndividualDetails, setRoleCode, setPartyId, setPartyCode, setFirstName, setLastName, setEmailAddress, setPhoneNumber, updateBrokerCedentPayload } = individualDetails.actions;
export default individualDetails.reducer;
