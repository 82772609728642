import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextFieldAtom, TypographyAtom, useTheme } from '@epam/reactjs-common';
import BoxStyled from '../../@atoms/layout/box/index.atom';
// import { TextareaAutosize} from '@mui/base/TextareaAutosize';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
export default function TextFieldComponent(props) {
    const { className, width, isDisabled, name, label, isRequired, wrapperPadding, error, labelMarginBottom, helperText, handleTextChange, placeholder, customInpStyle = {}, value = '', sx = { marginTop: '-10px' }, InputLabelProps, id, multiline = false, rows, autosize = false, labelsx } = props;
    const themehook = useTheme();
    return (_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'column',
            justifyContent: 'flex-start', width: width ? width : '300px', padding: wrapperPadding ? wrapperPadding : '10px', borderRadius: '5px', border: '0px solid lightgray' } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { fontSize: '12px', marginBottom: labelMarginBottom ? labelMarginBottom : '10px' } }, { children: label && (_jsxs(BoxStyled, Object.assign({ className: className, sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(TypographyAtom, Object.assign({ sx: Object.assign(Object.assign({}, themehook.customStyles.labelStyle), labelsx) }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                                fontSize: '12px', color: 'red', paddingLeft: '5px'
                            }, component: "p" }, { children: "*" })))] }))) })), multiline === false && _jsx(TextFieldAtom, { id: id, name: name, value: value, onChange: handleTextChange, placeholder: placeholder, inputProps: customInpStyle, disabled: isDisabled, error: error, helperText: helperText }), multiline === true &&
                _jsx(TextFieldAtom, { error: error, id: id, multiline: true, rows: rows, defaultValue: value, placeholder: placeholder, disabled: isDisabled, inputProps: customInpStyle, onChange: handleTextChange, helperText: helperText }), autosize === true &&
                _jsx(BaseTextareaAutosize, { placeholder: "Minimum 3 rows", minRows: 1 })
            // <EmptyTextarea 
            // aria-label={label}
            //   // id={id}
            //   // minRows={3} 
            //   // value={value}
            //   // sx={{width:'100%' }}
            //   // defaultValue={value}
            //   // placeholder={placeholder} 
            //   // disabled={isDisabled}
            //   // inputProps={customInpStyle}
            //   // onChange={(e) => handleTextChange(e.target.value)}
            // />
        ] })));
}
