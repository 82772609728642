import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    apiError: [],
    showApiError: false,
    locValidationError: false,
    locError: {
        country: '',
        postalCode: '',
        city: '',
        stateProvinceCode: '',
        addressLine1: '',
        addressLine2: '',
        isKeyAddress: '',
        buildings: '',
        content: '',
        totalSumInsured: '',
        deductible: '',
        deductibleType: '',
        construction: '',
        occupancy: '',
        sprinkled: '',
        grossArea: '',
        numberOfStories: '',
        builtYear: '',
        businessInterruption: '',
        timeElement: '',
        sir: ''
    },
    fileUrl: ''
};
const apiErrorSlice = createSlice({
    name: 'apiError',
    initialState,
    reducers: {
        setShowApiError: (state, action) => {
            state.showApiError = action.payload;
        },
        setApiError: (state, action) => {
            state.apiError = action.payload;
        },
        setLocValidtionError: (state, action) => {
            state.locError = action.payload;
        },
        setFileUrl: (state, action) => {
            state.fileUrl = action.payload;
        },
    },
});
export const { setShowApiError, setApiError, setLocValidtionError, setFileUrl } = apiErrorSlice.actions;
export default apiErrorSlice.reducer;
