import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    LayersRequestedData: {
        attachmentPoint: null,
        limit: null,
        partOf: null,
        tiv: null,
        sharesRequested: null,
        layerNetRetentionAndTreaty: null
    }
};
const layersRequestedSlice = createSlice({
    name: 'layersRequestedDetails',
    initialState,
    reducers: {
        setLayersRequestedDetails: (state, action) => {
            state.LayersRequestedData = Object.assign(Object.assign({}, state.LayersRequestedData), action.payload);
        },
        clearLayersRequestedDetails: (state) => {
            state.LayersRequestedData = initialState.LayersRequestedData;
        },
        setLayersRequestedBind: (state, action) => {
            var _a;
            //console.log("setLayersRequestedBind: ",action.payload);
            let _propertySubmissionInfo = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.propertySubmission;
            state.LayersRequestedData.attachmentPoint = _propertySubmissionInfo.attachmentPoint;
            state.LayersRequestedData.limit = _propertySubmissionInfo.limit;
            state.LayersRequestedData.partOf = _propertySubmissionInfo.partOf;
            state.LayersRequestedData.tiv = (_propertySubmissionInfo.tiv);
            state.LayersRequestedData.sharesRequested = (_propertySubmissionInfo.sharesRequested);
            state.LayersRequestedData.layerNetRetentionAndTreaty = (_propertySubmissionInfo.layerNetRetentionAndTreaty);
        }
    },
});
export const { setLayersRequestedDetails, clearLayersRequestedDetails, setLayersRequestedBind } = layersRequestedSlice.actions;
export default layersRequestedSlice.reducer;
