export var DocumentTypeEnum;
(function (DocumentTypeEnum) {
    DocumentTypeEnum["Referral"] = "Referral";
    DocumentTypeEnum["Recommendation"] = "Recommendation/Referral for Approval";
})(DocumentTypeEnum || (DocumentTypeEnum = {}));
export var DocumentCategoryEnum;
(function (DocumentCategoryEnum) {
    DocumentCategoryEnum["Referrals"] = "Referrals";
})(DocumentCategoryEnum || (DocumentCategoryEnum = {}));
(function (DocumentTypeEnum) {
    DocumentTypeEnum["Quote"] = "Quote";
    DocumentTypeEnum["Certificates"] = "Certificates";
})(DocumentTypeEnum || (DocumentTypeEnum = {}));
export var DocumentsSID;
(function (DocumentsSID) {
    DocumentsSID["EverTechUW"] = "S-1-5-21-2212250132-3848335397-3323957193-142323";
    DocumentsSID["EvertechTA"] = "S-1-5-21-2212250132-3848335397-3323957193-142322";
})(DocumentsSID || (DocumentsSID = {}));
export var DocumentContainer;
(function (DocumentContainer) {
    DocumentContainer["Correspondence"] = "correspondence";
})(DocumentContainer || (DocumentContainer = {}));
export var DocumentRepoName;
(function (DocumentRepoName) {
    DocumentRepoName["Dev"] = "https://evertechsadev.blob.core.windows.net";
    DocumentRepoName["QA"] = "https://evertechsaqa.blob.core.windows.net";
})(DocumentRepoName || (DocumentRepoName = {}));
