import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezone = "America/New_York";
let toToDay = moment.utc();
let currentDate = toToDay.tz(timezone).format('YYYY-MM-DD');
let oneYearLater = toToDay.add(1, 'years').tz(timezone).format('YYYY-MM-DD');
const initialState = {
    dateInfoData: {
        policyEffectiveDate: currentDate,
        policyExpirationDate: oneYearLater,
        reinsuranceEffectiveDate: currentDate,
        reinsuranceExpirationDate: oneYearLater,
        policyType: '',
        policyTypeId: '',
    }
};
const datesInfoSlice = createSlice({
    name: 'dateDetails',
    initialState,
    reducers: {
        setPolicyEffectiveDate: (state, action) => {
            state.dateInfoData.policyEffectiveDate = action.payload;
        },
        setPolicyExpirationDate: (state, action) => {
            state.dateInfoData.policyExpirationDate = action.payload;
        },
        setReinsuranceEffectiveDate: (state, action) => {
            state.dateInfoData.reinsuranceEffectiveDate = action.payload;
        },
        setReinsuranceExpirationDate: (state, action) => {
            state.dateInfoData.reinsuranceExpirationDate = action.payload;
        },
        setPolicyType: (state, action) => {
            state.dateInfoData.policyType = action.payload;
        },
        setPolicyTypeId: (state, action) => {
            state.dateInfoData.policyTypeId = action.payload;
        },
        clearDatesDetails: (state) => {
            state.dateInfoData = initialState.dateInfoData;
        },
        clearDatesFileds: (state) => {
            // Reset only the date fields
            state.dateInfoData.policyEffectiveDate = '';
            state.dateInfoData.policyExpirationDate = '';
            state.dateInfoData.reinsuranceEffectiveDate = '';
            state.dateInfoData.reinsuranceExpirationDate = '';
        },
    },
});
export const { clearDatesFileds, clearDatesDetails, setPolicyEffectiveDate, setPolicyExpirationDate, setReinsuranceEffectiveDate, setReinsuranceExpirationDate, setPolicyType, setPolicyTypeId } = datesInfoSlice.actions;
export default datesInfoSlice.reducer;
