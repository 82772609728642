import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    attatchmentData: []
};
const attatchmentDetailsSlice = createSlice({
    name: 'attatchmentData',
    initialState,
    reducers: {
        setAttatchmentsDetails: (state, action) => {
            state.attatchmentData = action.payload;
        },
    },
});
export const { setAttatchmentsDetails } = attatchmentDetailsSlice.actions;
export default attatchmentDetailsSlice.reducer;
