 export const payload={
  "submissionId": 0,
  "newStatus": "string",
  "lastChangeBy": 0,
  "declinationReasonCode": "string",
  "declinationReasonComment": "string",
  "completionTS": "",
  "approveCommentsForBrokerClientFromUW": "string",
  "internalUWNotes": "string",
  "isSendCustomEmail": true,
  "customEmailDetails": {
    "bodyContentType": "text\\html",
    "from": "",
    "sendTo": "",
    "subject": "",
    "body": "",
    "attachments": [
    ]
  }
}