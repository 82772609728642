var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export default (_a) => {
    var props = __rest(_a, []);
    const { palette, size, customPalette } = props.theme;
    return `
  &.scrollable{
    overflow-y: auto;
    padding-right: ${size(1)}px;
  }
  &::-webkit-scrollbar
  {
    width: ${size(1)}px;
    background-color: none;
  }
  &::-webkit-scrollbar-track
  {
    border-radius: ${size(1)}px;
    border-width: ${size(1)}px;
    background-color: ${customPalette.grey.draggedTransparent};
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: ${palette.primary.main};
    border-radius: ${size(1)}px;
  }
 &.country-form
  {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
      `;
};
