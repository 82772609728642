import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import '../../pages/submisson_result/ConfirmationPopUp.css';
import { useNavigate } from 'react-router-dom';
import SubmissionReviewIcon from "../../assets/icon/SubmissionDeclinePopup.svg";
import BoxStyled from '../../@atoms/layout/box/index.atom';
import { ButtonAtom, DialogActionsAtom, DialogAtom, DialogContentAtom, TypographyAtom } from "@epam/reactjs-common";
//
const ConfirmationPopUp = ({ dateTime, handleYesClick, handleNoClick, onClose, onGoBack, msg, msg1, subMsg, title }) => {
    const navigate = useNavigate();
    const styles = {
        errorMessageStyle: {
            fontWeight: '500',
            fontSize: '18px',
            color: '#4A4A4A',
            marginY: '8px',
            marginleft: '10px',
            textTransform: 'none',
            paddingLeft: '10px'
        },
        boxStyle: {
            display: 'flex',
            marginY: '10px',
            marginRight: '10px'
        },
        yesButtonStyle: {
            width: '30%',
            left: '2%',
            fontWeight: 'Bold',
            borderRadius: '0px'
        },
        noButtonStyle: {
            width: '30%',
            right: '1%',
            fontWeight: 'Bold',
            borderRadius: '0px',
            border: '1px solid #276ef1'
        },
        modelStyle: {
            width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center'
        }
    };
    return (_jsxs(DialogAtom, Object.assign({ open: true, sx: {
            padding: "2em",
            ".MuiDialog-paper": {
                maxWidth: "unset"
            }
        } }, { children: [_jsx(DialogContentAtom, Object.assign({ sx: { pr: 4, pl: 4, pb: 1 } }, { children: _jsxs(BoxStyled, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', marginRight: '10px' } }, { children: [_jsx(SubmissionReviewIcon, {}), _jsx(TypographyAtom, Object.assign({ id: "desc", sx: { mt: '2px', ml: '5px', fontSize: "15px !important" } }, { children: msg }))] })), _jsx(TypographyAtom, Object.assign({ id: "desc", sx: { fontSize: "15px !important", paddingBottom: '12px', ml: '2.2rem' } }, { children: msg1 })), _jsx(TypographyAtom, Object.assign({ id: "desc", sx: { fontSize: "15px !important", ml: '2.2rem' } }, { children: subMsg }))] }) })), _jsx(DialogActionsAtom, Object.assign({ sx: { display: "flex", justifyContent: "center", pb: 4 } }, { children: _jsxs(BoxStyled, { children: [_jsx(ButtonAtom, Object.assign({ variant: "outlined", color: "primary", sx: { width: "177px", mr: 2, padding: "11px 9px", fontWeight: "600", borderRadius: "0px", border: "1px solid #215EF7" }, onClick: handleNoClick }, { children: "No" })), _jsx(ButtonAtom, Object.assign({ variant: "contained", color: "primary", onClick: handleYesClick, sx: { width: "177px", mr: 1, padding: "11px 9px", fontWeight: "bold", borderRadius: "0px", border: "1px solid #215EF7" } }, { children: "Yes" }))] }) }))] })));
};
export default ConfirmationPopUp;
