import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import DashboardComponent from '../../components/dashboard/dashboard.component';
import { useSelector } from 'react-redux';
import { RoleCodeEnum } from '../../utils/enum/RoleCodeEnum';
import { useLocation } from 'react-router-dom';
import UWDashboardComponent from '../../components/dashboard/UWDashboard.component';
function DashboardPage() {
    var _a, _b;
    /* START - Hooks */
    const locationParams = useLocation();
    const profileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    const isUw = ((_a = profileData === null || profileData === void 0 ? void 0 : profileData.individual) === null || _a === void 0 ? void 0 : _a.roleCode) === RoleCodeEnum.Underwriter;
    useEffect(() => {
        /*
        console.log("Call - Proxy Server API ")
        axios.post("http://localhost:8000/middlewareapi",{
          "url": "https://da-services.everestre.com/everest-global-services/dev/evertech/v1.0/UserProfile/User/p001",
          "type" : "get",
          "body" : null,
          "accessToken": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IkwxS2ZLRklfam5YYndXYzIyeFp4dzFzVUhIMCJ9.eyJhdWQiOiI4OWFjM2RmMS01NDk3LTRiZjgtOWE2Zi0xNjIzMTQwMzc0NGYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMDAwMmNkMjQtN2RkNi00NTQyLWFjZDYtZTBjN2UxODRjODBjL3YyLjAiLCJpYXQiOjE3MTYxOTAwMjgsIm5iZiI6MTcxNjE5MDAyOCwiZXhwIjoxNzE2MTk0MTYzLCJhaW8iOiJBV1FBbS84V0FBQUFiTEJ5TFVkRnJkclNEUWRrMFRDZ2tpWFp1ekwvTTJCTDJuYW5tMHNIVXY2NEM4ajhWTTU2djh5bTY0c3NmRHQ1LzByOXRmRDV4ZXhoQzhPc0pOckw1UnlVWFpPL2dQdXlpODdDQ09USUtzR1Y2ZU10OHBlT0FwVHF3ZHdCZ1B6SyIsImF6cCI6IjNlYWRjOTA4LTIzNjctNDdmZi1hODk4LTBiZTUzNzU0YTI3ZiIsImF6cGFjciI6IjAiLCJuYW1lIjoiTmlzaGFudCBTaHVrbGEiLCJvaWQiOiIwOWRhNmFiNS04M2FlLTRjNzItODViNS1iYmVhZmM2NjQ1NTEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJuaXNoYW50LnNodWtsYUBldmVyZXN0cmUuY29tIiwicmgiOiIwLkFRTUFKTTBDQU5aOVFrV3MxdURINFlUSURQRTlySW1YVlBoTG1tOFdJeFFEZEU4QkFaRS4iLCJzY3AiOiJzdWJtaXNzaW9uLmFsbC5yZWFkd3JpdGUiLCJzdWIiOiJzQm14S0VnaG5lVzdDZEYtVDRrSGZuR0M5cjlPcU1qSzhkT2dtbEN4R3Q4IiwidGlkIjoiMDAwMmNkMjQtN2RkNi00NTQyLWFjZDYtZTBjN2UxODRjODBjIiwidXRpIjoiaWd6RkZNVDVkRXlvd3hrT3BrLTJBQSIsInZlciI6IjIuMCJ9.DkTVaZmksQj_HGR8fohbO2dj1Kfxx6s5fIPLr_IdMB4iFdOHpGa84bSPG2TcuZEwPPzWIKRjlWtN7TejXkE7ik0zlCQrhbKTCcUYdxQNBLgfrpytk9wANJpX5DCkQtLyvCwmLkP44Mm3XQPXd3X7yDlWKB2T4ajIvsrCb0IR01cBx1XeXXvj_-zBESj1KJsdAx5exjpKy4fqN6Kwlm_LR9F4CW9n_R8EGP1nGVlHfLAn1xcH-K7AEEtYnwraHvHRFxtIDbbUxRxaCvuLo7WmsJOPnS-a-XOv6WDWxp2d3Z2Oltn-NimRc9mZc6DR5zzqLnqkpPtENCQirNyoPhkmbg"
          
      }).then((result)=>{
    
        console.log("Proxy Server API  - Response : ",result.data)
        }).catch((error)=>{
          console.log("error: ",error)
        })
        */
    }, []);
    return _jsxs("div", Object.assign({ style: { padding: "15px", width: "100%", backgroundColor: isUw ? "#E2EAFE" : "unset" } }, { children: [((_b = profileData === null || profileData === void 0 ? void 0 : profileData.individual) === null || _b === void 0 ? void 0 : _b.roleCode) === RoleCodeEnum.Underwriter ? _jsx(UWDashboardComponent, {}) :
                _jsx(DashboardComponent, {}), " "] }));
}
export default DashboardPage;
