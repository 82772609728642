import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    locationAddressData: [],
};
const locationAddressSlice = createSlice({
    name: 'locationAddressDetails',
    initialState,
    reducers: {
        setLocationAddressDetails: (state, action) => {
            state.locationAddressData = action.payload;
        },
    },
});
export const { setLocationAddressDetails } = locationAddressSlice.actions;
export default locationAddressSlice.reducer;
