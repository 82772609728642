export const defaultCurrency = 0;
export const currencyHundredths = defaultCurrency + 2;
export const roadSpeeds = [
    'Motorways',
    'DualCarriageARoads',
    'SingleCarriageARoads',
    'BRoads',
    'UnclassifiedRoads',
    'AllOffMapJourneys',
];
export const maximumRoadSpeeds = [
    'Motorways',
    'DualCarriageARoads',
    'SingleCarriageARoads',
    'BRoads',
    'UnclassifiedRoads',
];
export var keyCodesData;
(function (keyCodesData) {
    keyCodesData["Enter"] = "Enter";
})(keyCodesData || (keyCodesData = {}));
export const keyCodes = keyCodesData;
export const noOfYearConstant = 'Select';
