import { createSlice } from '@reduxjs/toolkit';
const BROKERCOMMISSIONDEFAULT = 10;
const initialState = {
    commissionData: {
        commissionCede: 0,
        commissionBrokerage: BROKERCOMMISSIONDEFAULT,
        layerAnnualizedClientOfferAmt: 0,
    }
};
const commissionSlice = createSlice({
    name: 'commissionDetails',
    initialState,
    reducers: {
        setCommissionDetails: (state, action) => {
            //console.log("action.payload: ",action.payload)
            state.commissionData = Object.assign(Object.assign({}, state.commissionData), action.payload);
        },
        clearCommissionDetails: (state) => {
            state.commissionData = initialState.commissionData;
        },
        setCommissionDetailsBind: (state, action) => {
            var _a;
            //console.log("setCommissionDetailsBind: ",action.payload);
            let _propertySubmissionInfo = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.propertySubmission;
            state.commissionData.commissionCede = _propertySubmissionInfo.commissionCede;
            state.commissionData.commissionBrokerage = _propertySubmissionInfo.commissionBrokerage;
            state.commissionData.layerAnnualizedClientOfferAmt = (_propertySubmissionInfo.layerAnnualizedClientOfferAmt);
        }
    },
});
export const { setCommissionDetails, clearCommissionDetails, setCommissionDetailsBind } = commissionSlice.actions;
export default commissionSlice.reducer;
