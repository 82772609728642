var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, createContext, useMemo } from 'react';
import ThemesWrapper from '@epam/reactjs-common/theme';
import { CssBaseline } from '@epam/reactjs-common/styles';
import ArsGlobalStyles from '@themes/global_styles';
import lightTheme from '@themes/light_theme/light.theme.json';
import darkTheme from '@themes/dark_theme/dark.theme.json';
import createTheme from './createTheme';
//Context: creating
export const StateGlobalTheme = createContext(null);
//Context: with switch function
export default function ThemeProviderStyled(_a) {
    var props = __rest(_a, []);
    //Boolean: for Theme Switch
    const [isThemeDark, setisThemeDark] = useState(false);
    //Boolean: for Button Click Event
    const isThemeDarkFn = () => setisThemeDark(!isThemeDark);
    //Theme object: based on switch boolean
    const updatedTheme = useMemo(() => (isThemeDark ? createTheme(darkTheme) : createTheme(lightTheme)), [isThemeDark]);
    return (
    //Theme Context Provider to access switch function globally
    _jsx(StateGlobalTheme.Provider, Object.assign({ value: { isThemeDark, isThemeDarkFn } }, { children: _jsxs(ThemesWrapper, Object.assign({ theme: updatedTheme }, { children: [_jsx(CssBaseline, {}), _jsx(ArsGlobalStyles, {}), props.children] })) })));
}
