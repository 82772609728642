import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    referenceData: []
};
const referenceDetailsSlice = createSlice({
    name: 'referenceData',
    initialState,
    reducers: {
        setReferenceDetails: (state, action) => {
            state.referenceData = action.payload;
        },
    },
});
export const { setReferenceDetails } = referenceDetailsSlice.actions;
export default referenceDetailsSlice.reducer;
