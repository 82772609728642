import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    contactusDetailsData: [],
};
const contactUsDetailsSlice = createSlice({
    name: 'contactUsDetails',
    initialState,
    reducers: {
        setContactUsDetails: (state, action) => {
            state.contactusDetailsData = action.payload;
        },
    },
});
export const { setContactUsDetails } = contactUsDetailsSlice.actions;
export default contactUsDetailsSlice.reducer;
