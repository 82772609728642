import { createSlice } from '@reduxjs/toolkit';
import { UpdateSubmissionEvent } from '../../utils/enum/UpdateSubmissionEvent';
// Define the initial state
const initialState = {
    submissionData: {
        updateSubmission: {
            submissionId: 0,
            clientId: 0,
            brokerId: 0,
            insuredName: '',
            reinsuranceContractType: '',
            submissionStatus: '',
            uwsSubmissionId: '',
            submissionCountry: '',
            isSubmitted: true,
            isValidationRequired: false,
            isDeleted: false,
            createByUserId: '',
            createTS: '',
            lastChgByUserId: null,
            lastChgTS: null,
            submissionLOBs: [],
            propertySubmission: {
                propertySubmissionId: 0,
                submissionId: 0,
                paperCompany: '',
                clientPolicyNumber: '',
                effectiveDate: '',
                expirationDate: '',
                reinsuranceEffectiveDate: '',
                reinsuranceExpirationDate: '',
                policyType: '',
                pricingData: '',
                isFirePeril: false,
                sublimitForFirePeril: 0,
                isBoilerMachineryPeril: false,
                sublimitForBoilerMachineryPeril: 0,
                isWindPeril: false,
                sublimitForWindPeril: 0,
                isFloodIncSurgePeril: false,
                sublimitForFloodIncSurgePeril: 0,
                isFloodExclSurgePeril: false,
                sublimitForFloodExclSurgePeril: 0,
                numberOfYearsReported: 0,
                numberOfLossesOverTBD: 0,
                largeIndividualLoss: 0,
                claimValuationDate: '',
                attachmentPoint: null,
                limit: null,
                partOf: null,
                tiv: null,
                sharesRequested: null,
                commissionCede: 0,
                commissionBrokerage: null,
                layerAnnualizedClientOfferAmt: 0,
                annualGrossPremium: 0,
                uwAdjAmt: null,
                lastQuotedDate: '',
                isDeleted: false,
                createByUserId: '',
                createTS: '',
                lastChgByUserId: null,
                lastChgTS: null,
                addresses: [],
            },
        },
        isRequestForStatusUpdate: false,
        isRequestForUWCommentsUpdate: false,
        updateSubmissionEvent: UpdateSubmissionEvent
    }
};
const submissionFormDetailsSlice = createSlice({
    name: 'submissionFormDetails',
    initialState,
    reducers: {
        setSubmissionDetails: (state, action) => {
            state.submissionData = action.payload;
        },
        setIsSubmitedFlag: (state, action) => {
            state.submissionData = Object.assign(Object.assign({}, state.submissionData), { updateSubmission: Object.assign(Object.assign({}, state.submissionData.updateSubmission), { isSubmitted: true }) });
        },
        setIsRequestForStatusUpdate: (state, action) => {
            state.submissionData = Object.assign(Object.assign({}, state.submissionData), { isRequestForStatusUpdate: true });
        }
    },
});
export const { setSubmissionDetails, setIsSubmitedFlag, setIsRequestForStatusUpdate } = submissionFormDetailsSlice.actions;
export default submissionFormDetailsSlice.reducer;
