import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GET_Method } from "../../components/customhooks/useAxios";
import { clearRetrivedSubmissionDetails, setRetrivedSubmissionDetails } from "../../store/slices/retrivedSubmissionReducer";
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId } from "../../utils/CommonUtilties";
import LoaderComponent from "../../components/loader/loader.component";
import { setIsLinkedPage } from "../../store/slices/isLinkedPageReducer";
import { setBusinessOverView } from "../../store/slices/businessOverviewReducer";
import { setPolicyInfo } from '../../store/slices/policyInfoReducer';
import { setPerilsCoveredDetailsBind } from '../../store/slices/pearlsCoveredReducer';
import { setLossInformationBind } from '../../store/slices/lossInfoReducer';
import { setLayersRequestedBind } from '../../store/slices/layersRequestedReducer';
import { setCommissionDetailsBind } from '../../store/slices/commissionReducer';
import { setSubmissionDetails } from '../../store/slices/submissionFormDetailsReducer';
import { setLoctionAddressDetails } from "../../store/slices/locationAddressReducer";
import UWReferralsComponent from "../../components/referrals/UWReferrals.component";
/* Under Writer Submission Referal Details  */
export default function UWReferralsDetailsPage() {
    const pid = getLoginUserId();
    const submissionId = useSelector((state) => state.submissionIdDetails.submissionId);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        console.log("Page: Submission Approval, bind, Referred, decline");
        if (submissionId > 0) {
            const SubRetrival_API_URL = `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Submissions/${submissionId}?personId=${pid}`;
            const axiosParams = {
                url: SubRetrival_API_URL,
                methodType: "get"
            };
            setIsLoading(true);
            GET_Method(SubRetrival_API_URL, axiosParams).then((response) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                dispatch(clearRetrivedSubmissionDetails());
                dispatch(setIsLinkedPage(false));
                dispatch(setRetrivedSubmissionDetails((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.submissionPayload));
                //console.log("submssionresponse", response?.data?.submissionPayload);
                dispatch(setBusinessOverView((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.submissionPayload));
                dispatch(setPolicyInfo((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.submissionPayload));
                dispatch(setPerilsCoveredDetailsBind((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.submissionPayload));
                dispatch(setLossInformationBind((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.submissionPayload));
                dispatch(setLayersRequestedBind((_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.submissionPayload));
                dispatch(setCommissionDetailsBind((_g = response === null || response === void 0 ? void 0 : response.data) === null || _g === void 0 ? void 0 : _g.submissionPayload));
                dispatch(setLoctionAddressDetails((_k = (_j = (_h = response === null || response === void 0 ? void 0 : response.data) === null || _h === void 0 ? void 0 : _h.submissionPayload) === null || _j === void 0 ? void 0 : _j.propertySubmission) === null || _k === void 0 ? void 0 : _k.addresses));
                dispatch(setSubmissionDetails({
                    updateSubmission: response === null || response === void 0 ? void 0 : response.data.submissionPayload,
                    isRequestForStatusUpdate: false
                }));
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
            });
        }
    }, [submissionId]);
    return (_jsxs(_Fragment, { children: ["        ", isLoading ? _jsx(LoaderComponent, {}) :
                _jsx(UWReferralsComponent, { isAllDisabled: false })] }));
}
