import '../../../assets/styles/submissionform.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import { SubmissionStatusEnum } from '../../../utils/enum/SubmissionStatusEnum';
import { noOfYearConstant } from '../../../z_data/constants/form_constants.data';
import { getLoginUserId, parseNumber } from '../../../utils/CommonUtilties';
import { UpdateSubmissionEvent } from '../../../utils/enum/UpdateSubmissionEvent';
import { ReferralCompletionStatus } from '../../../utils/enum/ReferralCompletionStatus';
export const SubmissionFormData = () => {
    //console.log("in SubmissionFormData .....");
    var _a, _b, _c, _d, _e, _f;
    const submissionId = useSelector((state) => state.submissionIdDetails.submissionId);
    const submissionStatus = useSelector((state) => state.submissionIdDetails.submissionStatus);
    const businessOverviewData = useSelector((state) => state.businessOv.businessOverviewData);
    const policyInfowData = useSelector((state) => state.policyInfo.policyInfowData);
    const lossOfInfoData = useSelector((state) => state.lossInfo.lossOfInfoData);
    const perilsCoveredData = useSelector((state) => state.pearlsCoveredDetails.perilsCoveredData);
    //console.log("perilsCoveredData: ",perilsCoveredData)
    const policyEffectiveDate = useSelector((state) => state.datesInfo.dateInfoData.policyEffectiveDate);
    const policyExpirationDate = useSelector((state) => state.datesInfo.dateInfoData.policyExpirationDate);
    const reinsuranceEffectiveDate = useSelector((state) => state.datesInfo.dateInfoData.reinsuranceEffectiveDate);
    const reinsuranceExpirationDate = useSelector((state) => state.datesInfo.dateInfoData.reinsuranceExpirationDate);
    const policyTypeId = useSelector((state) => state.datesInfo.dateInfoData.policyTypeId);
    const locationBuildingInfoState = useSelector((state) => state.locationAddressDetails.loctionaddressDetailsData);
    const submissionDetailsState = useSelector((state) => state.submissionFormDetails.submissionData);
    const layersRequestedData = useSelector((state) => state.layerRequestedInfo);
    const commissionData = useSelector((state) => state.commissionData);
    const [searchParams] = useSearchParams();
    const [useProfileId] = useState((_a = getLoginUserId()) !== null && _a !== void 0 ? _a : '');
    //  buildingDetails
    const buildingDetails = {
        buildingInfoId: 0,
        buildings: 10,
        content: 11,
        deductible: 10,
        deductibleType: '123',
        construction: 'FM',
        occupancy: 'OC1',
        sprinkled: '1',
        fireProtectionClass: 0,
        itv: 0,
        grossArea: 10,
        numberOfStories: 1,
        builtYear: 2000,
        businessInterruption: 'abcd',
        timeElement: 12,
        sir: '111',
        isDeleted: false,
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: useProfileId,
        lastChgTS: new Date().toISOString()
    };
    //  Address
    const addresses = {
        addressId: 0,
        propertySubmissionAddressId: 0,
        addressLine1: '45 Main Street L1',
        addressLine2: '45 Main Street L2',
        city: 'pune',
        stateProvinceCode: 'IL',
        postalCode: '543333',
        country: businessOverviewData.countryId,
        latitude: '123',
        longitude: '234',
        comments: 'good',
        isDeleted: false,
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: useProfileId,
        lastChgTS: new Date().toISOString(),
        isKeyAddress: true,
        buildingDetails: buildingDetails
    };
    //  PropertySubmission
    let propertySubmission = {
        propertySubmissionId: (_c = (_b = submissionDetailsState === null || submissionDetailsState === void 0 ? void 0 : submissionDetailsState.updateSubmission) === null || _b === void 0 ? void 0 : _b.propertySubmission) === null || _c === void 0 ? void 0 : _c.propertySubmissionId,
        submissionId: (_d = submissionDetailsState === null || submissionDetailsState === void 0 ? void 0 : submissionDetailsState.updateSubmission) === null || _d === void 0 ? void 0 : _d.submissionId,
        paperCompany: policyInfowData.paperCompanyId,
        clientPolicyNumber: policyInfowData.policyNumber,
        effectiveDate: policyEffectiveDate,
        expirationDate: policyExpirationDate,
        reinsuranceEffectiveDate: reinsuranceEffectiveDate,
        reinsuranceExpirationDate: reinsuranceExpirationDate,
        policyType: policyTypeId,
        isFirePeril: perilsCoveredData.fireOnly,
        sublimitForFirePeril: (perilsCoveredData.fireOnlyVal === "" ? 0 : parseInt(perilsCoveredData.fireOnlyVal)),
        isBoilerMachineryPeril: perilsCoveredData.boilerAndMachinery,
        sublimitForBoilerMachineryPeril: perilsCoveredData.boilerAndMachineryVal === "" ? 0 : parseInt(perilsCoveredData.boilerAndMachineryVal),
        isWindPeril: perilsCoveredData.wind,
        sublimitForWindPeril: perilsCoveredData.windVal === "" ? 0 : parseInt(perilsCoveredData.windVal),
        isFloodIncSurgePeril: perilsCoveredData.floodIncSurge,
        sublimitForFloodIncSurgePeril: (perilsCoveredData.floodIncSurgeVal === "" || perilsCoveredData.floodIncSurgeVal === "NaN" || perilsCoveredData.floodIncSurgeVal === null || perilsCoveredData.floodIncSurgeVal === NaN) ? 0 : parseInt(perilsCoveredData.floodIncSurgeVal),
        isFloodExclSurgePeril: perilsCoveredData.floodExcSurge,
        sublimitForFloodExclSurgePeril: perilsCoveredData.floodExcSurgeVal === "" ? 0 : parseInt(perilsCoveredData.floodExcSurgeVal),
        numberOfYearsReported: lossOfInfoData.numberOfYearsReported == noOfYearConstant ? 0 : lossOfInfoData.numberOfYearsReported,
        numberOfLossesOverTBD: lossOfInfoData.numberOfLossesOverTBD,
        largeIndividualLoss: lossOfInfoData.largeIndividualLoss,
        claimValuationDate: lossOfInfoData.claimValuationDate,
        attachmentPoint: layersRequestedData.LayersRequestedData.attachmentPoint,
        limit: (layersRequestedData.LayersRequestedData.limit === null || layersRequestedData.LayersRequestedData.limit === "") ? 0 : parseNumber(layersRequestedData.LayersRequestedData.limit),
        partOf: (layersRequestedData.LayersRequestedData.partOf === null || layersRequestedData.LayersRequestedData.partOf === "") ? 0 : parseNumber(layersRequestedData.LayersRequestedData.partOf),
        tiv: (layersRequestedData.LayersRequestedData.tiv === null || layersRequestedData.LayersRequestedData.tiv === "") ? 0 : parseInt(layersRequestedData.LayersRequestedData.tiv),
        sharesRequested: (layersRequestedData.LayersRequestedData.sharesRequested === null || layersRequestedData.LayersRequestedData.sharesRequested === "") ? 0 : parseFloat(layersRequestedData.LayersRequestedData.sharesRequested),
        commissionCede: (commissionData.commissionData.commissionCede === null || commissionData.commissionData.commissionCede === "") ? 0 : parseFloat(commissionData.commissionData.commissionCede),
        commissionBrokerage: (commissionData.commissionData.commissionBrokerage === null || commissionData.commissionData.commissionBrokerage === "") ? 0 : parseFloat(commissionData.commissionData.commissionBrokerage),
        annualGrossPremium: 0,
        layerAnnualizedClientOfferAmt: (commissionData.commissionData.layerAnnualizedClientOfferAmt === null || commissionData.commissionData.layerAnnualizedClientOfferAmt === "") ? 0 : parseNumber(commissionData.commissionData.layerAnnualizedClientOfferAmt),
        layerNetRetentionAndTreaty: ((_f = (_e = layersRequestedData === null || layersRequestedData === void 0 ? void 0 : layersRequestedData.LayersRequestedData) === null || _e === void 0 ? void 0 : _e.layerNetRetentionAndTreaty) !== null && _f !== void 0 ? _f : 0),
        //lastQuotedDate: '2024-05-03T11:11:06.950Z',
        isPremiumUpdated: false,
        isCalculated: false,
        isDeleted: false,
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: useProfileId,
        lastChgTS: new Date().toISOString(),
        addresses: [addresses]
    };
    let submissionLOBReferral = {
        submissionLOBReferralId: 0,
        submissionLOBId: 0,
        completionStatus: ReferralCompletionStatus.Initialize,
        underWriterId: null,
        comments: "",
        internalUWNotes: "",
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: "006",
        lastChgTS: new Date().toISOString(),
        declinationReasonCode: '',
        underWriterSubmissionStatus: '',
        underWriterDeclinedComments: '',
        underWriterDeclinedReason: '',
        approveCommentsForBrokerClientFromUW: '' //LOB_REFERRAL_CHANGES
    };
    //  submissionLOBs
    let submissionLOB = {
        submissionLOBId: 0,
        lobType: policyInfowData.lobId,
        isDeleted: false,
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: useProfileId,
        lastChgTS: new Date().toISOString(),
        submissionLOBReferral: submissionLOBReferral
    };
    // Then create CreateSubmission
    const createSubmission = {
        submissionId: 0,
        clientId: businessOverviewData.clientId,
        brokerId: businessOverviewData.brokerId,
        insuredName: businessOverviewData.nameInsured,
        description: businessOverviewData.description,
        reinsuranceContractType: businessOverviewData.reInsContractTypeId,
        submissionStatus: SubmissionStatusEnum.DRAFT,
        uwsSubmissionId: '',
        submissionCountry: businessOverviewData.countryId,
        isSubmitted: true,
        isValidationRequired: false,
        isDeleted: false,
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: useProfileId,
        lastChgTS: new Date().toISOString(),
        NewRenewIndicator: '010',
        submissionLOBs: [submissionLOB],
        propertySubmission: propertySubmission
    };
    // Then create CreateSubmission
    let updateSubmission = {
        submissionId: submissionId,
        clientId: businessOverviewData.clientId,
        brokerId: businessOverviewData.brokerId,
        insuredName: businessOverviewData.nameInsured,
        description: businessOverviewData.description,
        reinsuranceContractType: businessOverviewData.reInsContractTypeId,
        submissionStatus: SubmissionStatusEnum.DRAFT,
        uwsSubmissionId: '',
        submissionCountry: businessOverviewData.countryId,
        isSubmitted: true,
        isValidationRequired: false,
        isDeleted: false,
        createByUserId: useProfileId,
        createTS: new Date().toISOString(),
        lastChgByUserId: useProfileId,
        lastChgTS: new Date().toISOString(),
        NewRenewIndicator: '010',
        submissionLOBs: [submissionLOB],
        propertySubmission: propertySubmission
    };
    //  create submissionData /* API Play Load Request */
    const createSubmissionDetailsRequest = {
        createSubmission
    };
    //  Update submissionData /* API Play Load Request */
    const updateSubmissionDetailsRequest = {
        updateSubmission,
        isRequestForStatusUpdate: false,
        isRequestForUWCommentsUpdate: false,
        updateSubmissionEvent: UpdateSubmissionEvent.None
    };
    // create attchment payload
    const createAttachmentPayload = {
        submissionId: submissionId,
        attachmentId: 0,
        isRefresh: false,
        attachmentType: "A",
        docCategory: "",
        createByUserId: useProfileId,
        repoContainerName: "",
        docMimeType: "",
        repoId: "",
        docIdInRepo: "",
        isDeleted: false,
        docType: "",
        submissionLOBId: 0
    };
    return { createSubmissionDetailsRequest, updateSubmissionDetailsRequest, createAttachmentPayload };
};
