import { AppRoutesConstants } from "./app_routes_data";
export const GridPageSizeConstant = 15;
export const GridPageSizeConstantForDashboard = 15;
export const GridPageSizeConstantForReferral = 15;
export const DeclinedCode = "INT-53";
export const ChangedDeclinedCode = "EXT-53";
export const getAgGridWidth = (locationParams, isSideNavOpen, setgridWidth) => {
    let _getAgGridWidth = "474px";
    //console.log("locationParams: ", locationParams);
    //console.log("contextAPI: ", contextAPI);
    if (locationParams == null)
        return _getAgGridWidth;
    if ((locationParams === null || locationParams === void 0 ? void 0 : locationParams.pathname) === AppRoutesConstants.SubmissionsListPage) {
        if (window.screen.availWidth === 1266 || window.screen.availWidth === 1280) {
            if (isSideNavOpen === true)
                _getAgGridWidth = "1015px";
            else
                _getAgGridWidth = "1150px";
        }
        else if (window.screen.availWidth === 1536) {
            if (isSideNavOpen === true)
                _getAgGridWidth = "1270px";
            else
                _getAgGridWidth = "1407px";
        }
        else {
            _getAgGridWidth = "960px";
        }
        //setisSort(true)
    }
    else if ((locationParams === null || locationParams === void 0 ? void 0 : locationParams.pathname) === "/" || locationParams.pathname === AppRoutesConstants.DashobardPage) {
        _getAgGridWidth = "480px";
        if (window.screen.availWidth === 1266 || window.screen.availWidth === 1280) {
            if (isSideNavOpen === true)
                _getAgGridWidth = "475px";
            else
                _getAgGridWidth = "500px";
        }
        else if (window.screen.availWidth === 1536) {
            if (isSideNavOpen === true)
                _getAgGridWidth = "604px";
            else
                _getAgGridWidth = "672px";
        }
    }
    setgridWidth((pre) => _getAgGridWidth);
    //console.log("isSideNavOpen -: ",isSideNavOpen)
    //console.log("_getAgGridWidth: ",_getAgGridWidth)
    return _getAgGridWidth;
};
