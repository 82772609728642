var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef } from 'react';
import { PublicClientApplication } from '@azure/msal-browser'; // Importing PublicClientApplication from MSAL library
import Cookies from 'js-cookie'; // Importing Cookies for managing cookies
import { jwtDecode } from 'jwt-decode';
export const AzureLogin = (props) => {
    // Directly fetch environment variables
    const clientId = process.env.REACT_APP_CLIENT_ID || '';
    const apiScopes = process.env.REACT_APP_API_SCOPES || '';
    const authority = process.env.REACT_APP_AUTHORITY || '';
    // MSAL configuration
    const msalConfig = {
        auth: {
            clientId: clientId,
            authority: authority,
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false, // Disable storing authentication state in cookie
        },
    };
    // Create an instance of PublicClientApplication
    const msalInstance = new PublicClientApplication(msalConfig);
    const REFRESH_THRESHOLD = 5 * 60; // 5 minutes in seconds ==> 300
    const TOKEN_CHECK_INTERVAL = 1 * 60 * 1000; // 1 minute in milliseconds ==> 60,000
    const interval = useRef(null);
    useEffect(() => {
        const handleRedirect = () => __awaiter(void 0, void 0, void 0, function* () {
            const redirectResponse = yield msalInstance.handleRedirectPromise();
            if (redirectResponse) {
                const accessToken1 = redirectResponse.accessToken;
                if (accessToken1) {
                    // Store token in HTTPOnly cookie for security
                    Cookies.set('accessToken', accessToken1, { httpOnly: true });
                }
            }
        });
        // handleRedirect();
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // Function to fetch access token
                const accessToken = yield fetchAccessToken();
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        });
        fetchData();
        const checkTokenExpiry = () => {
            const backendAccessToken = localStorage.getItem('accesstoken');
            if (backendAccessToken) {
                const decodeToken = jwtDecode(backendAccessToken);
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                const timeUntilExpiry = decodeToken.exp - currentTime;
                if (timeUntilExpiry <= REFRESH_THRESHOLD) { // Token is about to expire or has expired, refresh it
                    acquireTokenWithRefreshToken();
                }
            }
        };
        interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
        checkTokenExpiry(); // Check token expiry immediately after mounting
        // Add event listener to handle tab/browser close
        window.addEventListener('beforeunload', clearTokens);
        return () => {
            clearInterval(interval.current);
            window.removeEventListener('beforeunload', clearTokens);
        };
    }, []);
    // Function to fetch access token
    const fetchAccessToken = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield msalInstance.handleRedirectPromise();
            // Get all accounts from MSAL instance
            const accounts = msalInstance.getAllAccounts();
            // If no accounts found, initiate login redirect
            if (accounts.length === 0) {
                yield msalInstance.loginRedirect();
                return;
            }
            const tokenResponse = yield msalInstance.acquireTokenSilent({
                scopes: [apiScopes],
                account: accounts[0],
            });
            // Store token in local storage with custom key names
            localStorage.setItem('accesstoken', tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.accessToken);
            localStorage.setItem('Idtoken', tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.idToken);
            props.setAccessToken((val) => tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.accessToken);
            // Return the access token
            return tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.accessToken;
        }
        catch (error) {
            console.error('Error fetching access token:', error);
            throw error;
        }
    });
    const acquireTokenWithRefreshToken = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            fetchAccessToken();
        }
        catch (error) {
            console.log('Error refreshing token', error); // Handle token refresh error
        }
    });
    // Function to clear tokens
    const clearTokens = () => {
        const submissionId = localStorage.getItem('submissionId');
        localStorage.clear();
        if (submissionId) {
            try {
                localStorage.setItem('submissionId', submissionId);
            }
            catch (error) {
                console.error('Could not save submissionId to localStorage:', error);
            }
        }
    };
    // No UI elements to render, so we can return null
    return null;
};
