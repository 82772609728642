export const currencyFormat = (value) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 8
}).format(value);
export const appendDollar = (value) => {
    if (!value && value !== 0)
        return ""; // Check for null, undefined, and NaN
    const numberValue = parseFloat(value + ""); // Parse the string value to a floating-point number
    if (isNaN(numberValue))
        return "";
    const formattedValue = numberValue.toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
    });
    return formattedValue;
};
export const appendPercentage = (value) => {
    if (value === undefined || isNaN(value)) {
        return "";
    }
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value / 100); // Divide by 100 to convert to percentage
};
