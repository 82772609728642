import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isLinkedPage: false,
};
const isLinkedPageSlice = createSlice({
    name: 'isLinkedPage',
    initialState,
    reducers: {
        setIsLinkedPage: (state, action) => {
            state.isLinkedPage = action.payload;
        },
    },
});
export const { setIsLinkedPage } = isLinkedPageSlice.actions;
export default isLinkedPageSlice.reducer;
