import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    submissionId: 0,
    enableAutoSaveSubmission: false,
    enableAutoSaveBrokerOrClientComments: false,
    enableAutoSaveUWReferral: false,
    submissionStatus: "",
};
const submissionReducer = createSlice({
    name: 'submissionReducer',
    initialState,
    reducers: {
        setSubmissionId: (state, action) => {
            state.submissionId = action.payload;
            state.enableAutoSaveSubmission = false;
        },
        clearSubmissionId: () => {
            return initialState;
        },
        setSubmissionData: (state, action) => {
            return action.payload;
        },
        setEnableAutoSaveSubmission: (state, action) => {
            state.enableAutoSaveSubmission = action.payload;
        },
        setEnableAutoSaveUWReferral: (state, action) => {
            state.enableAutoSaveUWReferral = action.payload;
        },
        setSubmissionStatus: (state, action) => {
            state.submissionStatus = action.payload;
        },
        setEnableAutoSaveBrokerOrClientComments: (state, action) => {
            state.enableAutoSaveBrokerOrClientComments = action.payload;
        },
    },
});
export const { setSubmissionId, clearSubmissionId, setSubmissionData, setEnableAutoSaveSubmission, setEnableAutoSaveUWReferral, setSubmissionStatus, setEnableAutoSaveBrokerOrClientComments } = submissionReducer.actions;
export default submissionReducer.reducer;
