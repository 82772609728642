export var SubmissionStatusEnum;
(function (SubmissionStatusEnum) {
    SubmissionStatusEnum["DRAFT"] = "DR";
    SubmissionStatusEnum["QUOTED"] = "RQ";
    SubmissionStatusEnum["SUBJECTTOREFERRAL"] = "SR";
    SubmissionStatusEnum["REFERRED"] = "RF";
    SubmissionStatusEnum["APPROVED"] = "RA";
    SubmissionStatusEnum["DECLINED"] = "RD";
    SubmissionStatusEnum["BOUNDED"] = "RB";
})(SubmissionStatusEnum || (SubmissionStatusEnum = {}));
export var UnderWriterSubmissionStatus;
(function (UnderWriterSubmissionStatus) {
    UnderWriterSubmissionStatus["APPROVED"] = "UA";
    UnderWriterSubmissionStatus["DECLINED"] = "UD";
})(UnderWriterSubmissionStatus || (UnderWriterSubmissionStatus = {}));
export var UnderWriterAdjustmentType;
(function (UnderWriterAdjustmentType) {
    UnderWriterAdjustmentType["IncreaseByPercentage"] = "IP";
    UnderWriterAdjustmentType["DecreaseByPercentage"] = "DP";
    UnderWriterAdjustmentType["IncreaseByDollar"] = "IA";
    UnderWriterAdjustmentType["DecreaseByDollar"] = "DA";
})(UnderWriterAdjustmentType || (UnderWriterAdjustmentType = {}));
export var UnderWriterAdjustmentDefaultType;
(function (UnderWriterAdjustmentDefaultType) {
    UnderWriterAdjustmentDefaultType["IncreaseDecreaseByPercentage"] = "P";
    UnderWriterAdjustmentDefaultType["IncreaseDecreaseByDollar"] = "D";
})(UnderWriterAdjustmentDefaultType || (UnderWriterAdjustmentDefaultType = {}));
