/**
 * It takes a theme object and returns a new theme object with the mixins property added to it.
 * @param theme - Omit<ApThemeOptions, 'customMixins' | 'components'>
 * @returns An object with the following properties:
 * - mixins
 * - components
 */
const createTheme = (theme) => {
    return Object.assign(Object.assign({}, theme), { customMixins: {
            subTabHeight: 40,
        }, components: {
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: theme.customStyles.labelStyle.fontSize,
                        color: theme.customStyles.labelStyle.color,
                        marginLeft: "-10px",
                    },
                },
            },
            MuiInputBase: {
                defaultProps: {
                    size: "small"
                },
                styleOverrides: {
                    root: {
                        ".MuiInputBase-input": {
                            padding: "9px 10px ",
                            fontSize: "12px", // Increase font size to 12px
                        },
                        color: "black",
                        border: "0px solid #80808075",
                        ".css-1lkr5rz-MuiInputBase-input-MuiOutlinedInput-input": {},
                        ".MuiSelect-select": {},
                        ".MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled": {
                            border: "0px solid #80808075"
                        },
                        "& .Mui-disabled": {
                            color: "black",
                            opacity: 1,
                            WebkitTextFillColor: "rgba(0, 0, 0)", // Set text fill color for WebKit browsers
                        },
                    },
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: "small",
                },
                styleOverrides: {
                    root: {
                        "& .Mui-disabled": {
                            color: "black",
                            opacity: 1,
                            WebkitTextFillColor: "rgba(0, 0, 0)", // Set text fill color for WebKit browsers
                        },
                    },
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: "small"
                },
                styleOverrides: {
                    root: {
                        "& .Mui-disabled": {
                            color: "black",
                            opacity: 1,
                            WebkitTextFillColor: "rgba(0, 0, 0)", // Set text fill color for WebKit browsers
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                defaultProps: {
                    size: "small"
                },
                styleOverrides: {
                    root: {
                        fontFamily: theme.customStyles.fontfamily,
                        fontSize: "12px",
                        marginTop: "0.2rem",
                        ".Mui-disabled": {},
                        "& .Mui-disabled": {
                            color: "black",
                            opacity: 1,
                            WebkitTextFillColor: "rgba(0, 0, 0)", // Set text fill color for WebKit browsers
                        },
                        "&.Mui-disabled": {
                            color: "black",
                            opacity: 1,
                            WebkitTextFillColor: "rgba(0, 0, 0)", // For WebKit browsers
                        },
                    },
                }
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        "&.Mui-disabled": {
                            color: "black",
                            opacity: 1,
                            WebkitTextFillColor: "rgba(0, 0, 0)", // Set text fill color for WebKit browsers
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: theme.customPalette.primary.active,
                        fontFamily: theme.customStyles.fontfamily,
                        borderRadius: '0px',
                        '&.Mui-disabled': {
                            '&.MuiButton-outlinedPrimary': {
                                backgroundColor: theme.customPalette.grey.hover,
                                color: theme.customPalette.grey.disabled,
                            },
                            '&.MuiButton-containedPrimary': {
                                backgroundColor: theme.customPalette.grey.dragged,
                                color: theme.customPalette.grey.disabled,
                            },
                        },
                        '&.MuiButton-containedPrimary': {
                            backgroundColor: theme.customStyles.ButtonContainedPrimaryStyle.backgroundColor,
                            color: theme.customStyles.ButtonContainedPrimaryStyle.color,
                            fontWeight: theme.customStyles.ButtonContainedPrimaryStyle.fontWeight,
                            fontSize: theme.customStyles.ButtonContainedPrimaryStyle.fontSize
                        },
                        '&.MuiButton-outlinedPrimary ': {
                            backgroundColor: theme.customStyles.ButtonoutlinedPrimaryStyle.backgroundColor,
                            color: theme.customStyles.ButtonoutlinedPrimaryStyle.color,
                            fontWeight: theme.customStyles.ButtonoutlinedPrimaryStyle.fontWeight,
                            fontSize: theme.customStyles.ButtonoutlinedPrimaryStyle.fontSize
                        },
                        '&.MuiButton-textPrimary': {
                            backgroundColor: theme.customStyles.ButtonTextPrimaryStyle.backgroundColor,
                            color: theme.customStyles.ButtonTextPrimaryStyle.color,
                            fontWeight: theme.customStyles.ButtonTextPrimaryStyle.fontWeight,
                            fontSize: theme.customStyles.ButtonTextPrimaryStyle.fontSize
                        },
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        // This targets the root of the popover
                        "& li": {
                            fontFamily: theme.customStyles.fontfamily,
                            fontSize: "12px",
                            fontFamily: theme.customStyles.fontfamily,
                        }
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '12px', // Set font size to 12px for FormControlLabel
                    },
                    label: {
                        fontSize: '12px', // Ensure label text is 12px
                    }
                }
            },
            MuiGrid: {
                defaultProps: {
                    alignItems: 'center',
                    fontFamily: theme.customStyles.fontfamily,
                    fontSize: theme.customStyles.sidebarFontSize,
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        "#sidebar1": {
                            backgroundColor: theme.customStyles.sidebarBackgroundColor,
                            color: theme.customStyles.sidebarColor
                        },
                        ".MuiDialog-paper": {
                            backgroundColor: "#ffff"
                        }
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        ".MuiDialog-paper": {
                            backgroundColor: "#ffff"
                        }
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        "span": {
                            color: theme.customStyles.sidebarColor,
                            fontFamily: theme.customStyles.fontfamily,
                            fontSize: theme.customStyles.sidebarFontSize
                        }
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        minHeight: "1rem"
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.customStyles.CardStyle.backgroundColor,
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: "AvenirNextLTPro-Regular",
                    }
                }
            },
        } });
};
export default createTheme;
