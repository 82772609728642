var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import dropdown_popperStyles from './dropdown_popper.styles';
import datepicker_popperStyles from './datepicker_popper.styles';
import tooltip_popperStyles from './tooltip_popper.styles';
import global_notificationStyles from './global_notification.styles';
export default (_a) => {
    var props = __rest(_a, []);
    const { space, customPalette } = props.theme;
    return `
    html, body, #root{
      height: 100vh;
    }
    .demo-page {
      h4{
        font-weight: 400;
        letter-spacing: 0.3rem;
        word-spacing: 0.2rem;
        text-transform: uppercase;
        color: ${customPalette.grey.focus};
        box-shadow: 0px -4px 6px ${customPalette.grey.border};
        border-radius: 3px;
        border: 1px solid ${customPalette.grey.border};
        border-bottom: none;
        padding: ${space(1)}px ${space(2)}px 0px ${space(2)}px;
        margin: ${space(5)}px -${space(2)}px ${space(3)}px -${space(2)}px;
      }
    }
    .SnackbarContainer-root.SnackbarContainer-top.SnackbarContainer-right .MuiCollapse-root, 
    .SnackbarContainer-root.SnackbarContainer-top.SnackbarContainer-right .MuiCollapse-vertical, 
    .SnackbarContainer-root.SnackbarContainer-top.SnackbarContainer-right .MuiCollapse-wrapperInner, 
    .SnackbarContainer-root.SnackbarContainer-top.SnackbarContainer-right .SnackbarItem-wrappedRoot, 
    .SnackbarContainer-root.SnackbarContainer-top.SnackbarContainer-right .SnackbarContent-root {
      width: auto !important;
    }
    .SnackbarContent-root {
      min-width: 0 !important;
    }
    ${dropdown_popperStyles(props)}
    ${datepicker_popperStyles(props)}
    ${tooltip_popperStyles(props)}
    ${global_notificationStyles(props)}
  `;
};
