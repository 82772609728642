import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isTIVValdated: true,
    isPolicyDateValidated: true,
    isNameInsuredValidated: true
};
const isTIVValdatedSlice = createSlice({
    name: 'TIVValidation',
    initialState,
    reducers: {
        setTIVValidation: (state, action) => {
            state.isTIVValdated = action.payload;
        },
        setPolicyValidation: (state, action) => {
            state.isPolicyDateValidated = action.payload;
        },
        setNameInsuredValidation: (state, action) => {
            state.isNameInsuredValidated = action.payload;
        },
    },
});
export const { setTIVValidation, setPolicyValidation, setNameInsuredValidation } = isTIVValdatedSlice.actions;
export default isTIVValdatedSlice.reducer;
