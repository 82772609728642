import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isValidated: true,
};
const isValidatedSlice = createSlice({
    name: 'isValidated',
    initialState,
    reducers: {
        setIsValidated: (state, action) => {
            state.isValidated = action.payload;
        },
    },
});
export const { setIsValidated } = isValidatedSlice.actions;
export default isValidatedSlice.reducer;
