var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export default (_a) => {
    var props = __rest(_a, []);
    const { space } = props.theme;
    return `
    .SnackbarContainer-root{
      &.SnackbarContainer-top{
        &.SnackbarContainer-right{
          right: ${space(6.5)}px;
          top: ${space(6.5)}px;
          width: 33.33%;
          max-width: ${space(50)}px;
          flex-direction: column-reverse;
           .MuiCollapse-root, 
            .MuiCollapse-vertical,
            .MuiCollapse-wrapperInner,
            .SnackbarItem-wrappedRoot,
            .SnackbarContent-root {
              width:100%;
          }
        }
      }
    }
  `;
};
